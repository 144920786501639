export const UPDATE_FORUM_ID = 'UPDATE_FORUM_ID';
export const APP_DOWNLOAD_CHECK = 'APP_DOWNLOAD_CHECK';

export function updateForumId(forumId: number) {
    return {
        type: UPDATE_FORUM_ID,
        payload: {
            forumId
        }
    };
}

export function updateAppDownloadCheck(check: boolean) {
    return {
        type: APP_DOWNLOAD_CHECK,
        payload: {
            check
        }
    };
}

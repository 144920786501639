import styled from '@emotion/styled';
import Link from '../../../../components/Link';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import HoverDropdownMenuLink from './HoverDropdownMenuLinks';
import useTranslation from '../../../../hooks/useTranslation';
import { useState, useRef, MouseEvent } from 'react';
import { recordClick } from '@thestudentroom/datalayer';
import { runtimeConfig } from '../../../../runtimeConfig';
import { useAppTheme } from '@thestudentroom/lib.themes';

const NavLink = styled(Link)<{ open: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: ${(props) =>
        props.open ? props.theme.palette.primary.dark : props.theme.palette.primary.main};
    color: ${(props) => props.theme.white};
    text-decoration: none;
    ${(props) => props.theme.typography.body1};
    text-align: center;
    padding: ${(props) => props.theme.spacing(2, 3)};
    white-space: nowrap;

    box-shadow: ${(props) =>
        props.open ? `inset 0px -4px 0px ${props.theme.palette.success.main}` : 'none'};

    &:hover {
        background-color: ${(props) => props.theme.palette.primary.dark};
        text-decoration: none;
    }

    @media (min-width: 981px) and (max-width: 1270px) {
        padding: ${(props) => props.theme.spacing(2, 0)};
    }

    @media (min-width: 981px) and (max-width: 1074px) {
        white-space: normal;
    }

    @media (min-width: 1075px) and (max-width: 1270px) {
        white-space: no-wrap;
    }

    @media (max-width: 759px) {
        justify-content: flex-start;
        text-align: left;
        height: 30px;
    }
`;

const NavLinkContainer = styled.li<{ open: boolean }>`
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-basis: min-content;
    background-color: ${(props) =>
        props.open ? props.theme.palette.primary.dark : props.theme.palette.primary.main};

    @media (min-width: 981px) and (max-width: 1229px) {
        &:first-of-type {
            margin-left: ${(props) => props.theme.spacing(0)};
        }

        &:last-of-type {
            margin-right: ${(props) => props.theme.spacing(-2)};
        }
    }

    @media (min-width: 1350px) {
        &:first-of-type {
            padding-left: ${(props) => props.theme.spacing(0)};
            margin-left: ${(props) => props.theme.spacing(-2)};
        }

        &:last-of-type {
            padding-right: ${(props) => props.theme.spacing(0)};
            margin-right: ${(props) => props.theme.spacing(-3)};
        }
    }
`;

const NavHoverContainer = styled(Box)`
    border-top: 2px solid ${(props) => props.theme.palette.success.main};
    -webkit-box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.51);
    box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.51);
    background-color: ${(props) => props.theme.palette.primary.dark};
`;

type HoverMenuLinkProps = {
    index: string;
    titleKey: string;
    url?: string;
    children?: Array<HoverMenuLinkProps>;
};

export default function HoverMenuLink(props: HoverMenuLinkProps) {
    const { t } = useTranslation();
    const theme = useAppTheme();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [hoverWidth, setHoverWidth] = useState<number>(0);
    const hoverTimerRef = useRef<NodeJS.Timeout | null>(null);

    const openMenu = (event: MouseEvent<HTMLElement>) => {
        const parentElement = event.currentTarget.parentElement;

        hoverTimerRef.current = setTimeout(() => {
            setAnchorEl(parentElement);
            setHoverWidth(parentElement ? parentElement.offsetWidth + 25 : 0);
            setOpen(true);
        }, 200);
    };

    const closeMenu = (event: MouseEvent<HTMLElement>) => {
        const parentElement = event.currentTarget.parentElement;

        if (hoverTimerRef.current) {
            clearTimeout(hoverTimerRef.current);
        }

        setAnchorEl(parentElement);
        setHoverWidth(parentElement ? parentElement.offsetWidth : 0);
        setOpen(false);
    };

    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? 'hover-menu-' + props.index : undefined;
    const children = props.children && props.children.length ? props.children : null;

    // Convert any relative TSR links to absolute paths
    const initialURL = props.url ?? '';
    const fullURL = /^https?:\/\//i.test(initialURL)
        ? initialURL
        : runtimeConfig.appDomain + initialURL;

    return (
        <NavLinkContainer
            onMouseEnter={(e) => openMenu(e)}
            onMouseLeave={(e) => closeMenu(e)}
            open={open}
            data-cy="navigation-item"
        >
            <NavLink
                url={props.url || ''}
                onClick={() =>
                    recordClick({
                        group: 'navigating',
                        section: 'header',
                        subsection: 'main-navigation',
                        item: t(`navigation.${props.titleKey}`),
                        action: 'select',
                        url: fullURL
                    })
                }
                open={open}
            >
                {t(`navigation.${props.titleKey}`)}
            </NavLink>
            {children ? (
                <Popper
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    keepMounted
                    disablePortal={true}
                    transition
                    placement={'bottom'}
                    sx={{ zIndex: 1000 }}
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps}>
                            <NavHoverContainer
                                sx={{
                                    width: hoverWidth,
                                    p: theme.spacing(2),
                                    bgcolor: theme.white
                                }}
                            >
                                {children ? (
                                    <HoverDropdownMenuLink
                                        topParentKey={props.titleKey}
                                        items={children}
                                    />
                                ) : null}
                            </NavHoverContainer>
                        </Fade>
                    )}
                </Popper>
            ) : null}
        </NavLinkContainer>
    );
}

import styled from '@emotion/styled';
import TSRAdSlot from './TSRAdSlot';
import { useEffect, useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import { useSelector } from 'react-redux';
import { RootState } from 'client';
import OwnershipHeader from '../OwnershipHeader';

const AdContainer = styled.div<{ triggered: boolean, ownership: boolean}>`
    margin: ${(props) => props.theme.spacing(0, 'auto')};
    display: ${(props) => props.triggered ? 'flex' : 'none'};
    justify-content: center;
    width: 100%;
    max-width: ${(props) => props.ownership ? '1350px' : '100%'};
    padding: ${(props) => props.ownership ? props.theme.spacing(0, 3) : 0};
`;

export const LeaderboardToolbar = styled(Toolbar)<{ownership: boolean }>`
    padding: ${(props) => props.theme.spacing(0.5, 0)};
    background-color: ${(props) => props.ownership ? props.theme.palette.background.paper : props.theme.palette.primary.main};
    color: ${(props) => props.ownership ? props.theme.palette.text.primary : props.theme.palette.primary.contrastText};
    height: ${(props) => props.ownership ? '100px' : '62px'};
    box-sizing: border-box;

    @media (min-width: 720px) {
        height: 100px;
    }

    ${(props) => props.ownership ? `
        -webkit-box-shadow: 0px 2px 5px -3px rgba(0,0,0,0.8);
        -moz-box-shadow: 0px 2px 5px -3px rgba(0,0,0,0.8);
        box-shadow: 0px 2px 5px -3px rgba(0,0,0,0.8);
    ` : ''}
`;

interface HeaderLeaderboardProps {
    triggered: boolean;
    variant?: string;
}

export default function HeaderLeaderboard(props: HeaderLeaderboardProps) {
    const adUnit = 'tsr_header_leaderboard';
    const [active, setActive] = useState(false);
    const advertState = useSelector((state: RootState) => state.adverts);
    const pageTargeting = advertState.pageTargeting;

    useEffect(() => {
        if (props.triggered) {
            setActive(true);
        }
    }, [props.triggered]);


    return (
        <LeaderboardToolbar disableGutters={true} ownership={advertState.ownership_learning_provider}>
            {pageTargeting.p_t && (
                <AdContainer triggered={props.triggered} ownership={advertState.ownership_learning_provider} data-testid="header-toolbar">
                    <TSRAdSlot
                        slotId={adUnit}
                        sizes={[
                            [728, 90],
                            [320, 50],
                            [300, 50]
                        ]}
                        sizeMapping={[
                            [[728, 0], [[728, 90]]],
                            [[320, 0], [[320, 50], [300, 50]]]
                        ]}
                        adUnit={adUnit}
                        active={active}
                        targetingArguments={{ variation: props.variant }}
                    />
                    {advertState.ownership_learning_provider && (
                        <OwnershipHeader />
                    )}
                </AdContainer>
            )}
        </LeaderboardToolbar>
    );
}

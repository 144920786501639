/**
 * Get a security token for accessing vbulletin endpoints e.g. for
 * creating a post.
 * @returns a security token
 */
export default function getSecurityToken() {
    return fetch('/ajax.php?do=st')
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data.security_token;
        })
        .catch((error) => {
            return 'guest';
        });
}

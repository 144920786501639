import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { TSRTheme } from '@thestudentroom/lib.themes';
import useTranslation from './hooks/useTranslation';
import './App.css';
import { RootState } from './client';
import ErrorBoundary from './components/ErrorBoundary';
import ErrorPage from './pages/Error';
import ScrollToTop from './helpers/ScrollToTop';
import TSRAdsProvider from './components/Ads/TSRAdsProvider';
import { WithRumComponentContext } from './components/Datadog';
import MemberProvider from './providers/MemberProvider';
import GuestProvider from './providers/GuestProvider';
import Routes from './Routes';

const App = WithRumComponentContext(
    'App',
    ({ serverLocation, postParams }: { serverLocation?: URL; postParams: any }) => {
        const { t } = useTranslation();
        const userId = useSelector((state: RootState) => state.user.userId);
        const routes = <Routes postParams={postParams} serverLocation={serverLocation} />;

        return (
            <MuiThemeProvider theme={TSRTheme}>
                <CssBaseline />
                <TSRAdsProvider>
                    <Helmet>
                        <meta httpEquiv="X-UA-Compatible" content="IE=edge" charSet="utf-8" />
                        <meta
                            name="viewport"
                            content="width=device-width, initial-scale=1 maximum-scale=1.0 user-scalable=0"
                        />
                        <meta name="referrer" content="strict-origin" />

                        <title>{t('meta.title')}</title>

                        <link rel="preconnect" href="https://rules.quantcount.com" />

                        {/* Social logins */}
                        {parseInt(userId) === 0 && (
                            <>
                                <script
                                    src="https://accounts.google.com/gsi/client"
                                    async
                                    defer
                                ></script>
                                {/* <script async defer src="https://connect.facebook.net/en_US/sdk.js"></script> */}
                                <script
                                    async
                                    defer
                                    type="text/javascript"
                                    src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
                                ></script>
                            </>
                        )}
                    </Helmet>
                    <ErrorBoundary errorComponent={<ErrorPage errorType={'error'} />}>
                        <ScrollToTop>
                            {parseInt(userId) > 0 ? (
                                <MemberProvider>{routes}</MemberProvider>
                            ) : (
                                <GuestProvider>{routes}</GuestProvider>
                            )}
                        </ScrollToTop>
                    </ErrorBoundary>
                </TSRAdsProvider>
            </MuiThemeProvider>
        );
    }
);
export default App;

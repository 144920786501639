import { UserGroup, UserQualification } from '../../types/user';

export const UPDATE_USER_ID = 'UPDATE_USER_ID';
export const UPDATE_USERGROUP_ID = 'UPDATE_USERGROUP_ID';
export const UPDATE_SECURITY_TOKEN = 'UPDATE_SECURITY_TOKEN';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const SET_USER_SEGMENTS = 'SET_USER_SEGMENTS';

export function updateUserId(userId: number) {
    return {
        type: UPDATE_USER_ID,
        payload: {
            userId
        }
    };
}

export function updateUsergroupId(userGroupId: number) {
    return {
        type: UPDATE_USERGROUP_ID,
        payload: {
            userGroupId
        }
    };
}

export function updateUserDetails(
    loading: boolean,
    userGroupId: number,
    userGroups: UserGroup[],
    first_name: string,
    last_name: string,
    username: string,
    avatar: string,
    email: string,
    dob: string,
    gender: string,
    current_qualifications: UserQualification[],
    future_qualifications: UserQualification[],
    year_group: number = 0,
    career_phase: string = '',
    current_subjects: number[] = [],
    future_subjects: number[] = [],
    current_learning_providers: number[] = [],
    future_learning_providers: number[] = [],
    mobile: string = '',
    post_code: string = '',
    country: string = '',
    last_updated: string = '',
    questions_answered: number = 0
) {
    return {
        type: UPDATE_USER_DETAILS,
        payload: {
            loading,
            userGroupId,
            userGroups,
            first_name,
            last_name,
            username,
            avatar,
            email,
            dob,
            gender,
            current_qualifications,
            future_qualifications,
            year_group,
            career_phase,
            current_subjects,
            future_subjects,
            current_learning_providers,
            future_learning_providers,
            mobile,
            post_code,
            country,
            last_updated,
            questions_answered
        }
    };
}

export function updateSecurityToken(securityToken: string) {
    return {
        type: UPDATE_SECURITY_TOKEN,
        payload: {
            securityToken
        }
    };
}

export function setUserSegments(userSegments: {
    main: string[];
    super: string[];
    high: string[];
    medium: string[];
    low: string[];
    all: string[];
    adverts: string[];
}) {
    return {
        type: 'SET_USER_SEGMENTS',
        payload: userSegments
    };
}

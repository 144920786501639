import { AnyAction } from 'redux';
import { UPDATE_POST_DETAILS } from '../post/actions';

interface PostReducerState {
    postHash: string;
    postStartTime: number;
}

export const initialState: PostReducerState = {
    postHash: '',
    postStartTime: 0
};

export default function postReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case UPDATE_POST_DETAILS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

import { memo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Libraries
import { Outlet, useLocation } from 'react-router-dom';
import loadable from '@loadable/component';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

// Components
import WallPaperAdvert from '../../components/Ads/WallPaperAdvert';
import BottomSheetAdvert from '../../components/Ads/BottomSheetAdvert';
import ErrorBoundary from '../../components/ErrorBoundary';
import ErrorPage from '../../pages/Error';
import { RootState } from '../../client';
import Header from './Header';

import getSecurityToken from '../../helpers/getSecurityToken';
import getModalToDisplay from '../../helpers/Modals';
import { updateSecurityToken } from '../../store/user/actions';

import { LayoutMain, Offset, ExperimentOffset, StyledDialog } from './styles';

import './style.css';

const Footer = loadable(() => import('./Footer'));

export default memo(() => {
    const [isFrontend, setIsFrontend] = useState(false);
    const pageTargeting = useSelector((state: RootState) => state.adverts.pageTargeting);
    const location = useLocation();
    const dispatch = useDispatch();
    const isSmallEnoughForBottomSheet = useMediaQuery('(max-width:670px)');

    const userId = useSelector((state: RootState) => state.user.userId);
    const currentForumId = useSelector((state: RootState) => state.thread.forumId);
    const currentLocation = location.pathname + location.search;
    const modal = useSelector((state: RootState) => state.modals);

    useEffect(() => {
        setIsFrontend(typeof window !== 'undefined');
    }, []);

    useEffect(() => {
        if (userId) {
            getSecurityToken().then((token) => dispatch(updateSecurityToken(token)));
        }
    }, [userId, currentLocation]);

    // Header ad behaviour test
    const ownershipHeaderTest = useFeatureIsOn('tug-1229-ad-header-test');
    const RenderedOffset = ownershipHeaderTest ? ExperimentOffset : Offset;

    return (
        <>
            <LayoutMain>
                {pageTargeting.p_t ? (
                    <WallPaperAdvert key={`wallpaper-ad-${currentForumId}`} />
                ) : null}
                <Header key={`header-ad-${currentForumId}`} />
                {/* Offset added due to sticky nav toolbar https://mui.com/material-ui/react-app-bar/#fixed-placement */}
                <RenderedOffset
                    location={
                        location?.pathname.startsWith('/search')
                            ? 'search'
                            : location?.pathname.startsWith('/chat')
                            ? 'chat'
                            : ''
                    }
                />
                <ErrorBoundary errorComponent={<ErrorPage errorType={'error'} />}>
                    <Outlet />
                    {/* Modal */}
                    <StyledDialog
                        open={modal.showModal}
                        disableEscapeKeyDown={true}
                        onBackdropClick={() => false}
                        maxWidth={false}
                        fullScreen={isSmallEnoughForBottomSheet && !['ask_a_question'].includes(modal.modalType)}
                        // All dismissible modals can be full screen width/height, any non-dismissible modals should have a little gap around the edge
                        // With the exception of the 'existing_member' modal, this has a descriptive title of why it has popped up and requires the full screen for layout
                        $isFullScreen={modal.dismissible || (!modal.dismissible && modal.modalType === 'existing_member')}
                        $aaq={modal.modalType === "ask_a_question"}
                        $leadGen={modal.modalType === 'lead_gen'}
                    >
                        {getModalToDisplay(modal.modalType, modal.params)}
                    </StyledDialog>
                </ErrorBoundary>
                <Footer key={'footer'} />
            </LayoutMain>
            {isFrontend && <BottomSheetAdvert key={`bottomsheet-ad-${currentForumId}`} />}
        </>
    );
});

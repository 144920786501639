import { AnyAction } from 'redux';
import {
    UPDATE_USER_ID,
    UPDATE_USERGROUP_ID,
    UPDATE_USER_DETAILS,
    UPDATE_SECURITY_TOKEN,
    SET_USER_SEGMENTS
} from './actions';
import { UserGroup, UserQualification } from '../../types/user';
import getAgeGroupFromDOB from '../../helpers/User/getAgeGroupFromDOB';
import getUserAge from '../../helpers/User/getUserAge';

export interface UserReducerState {
    userId: number;
    loading: boolean;
    userGroupId: number;
    userGroups: UserGroup[];
    first_name: string;
    last_name: string;
    username: string;
    avatar: string;
    dob: string;
    age: string;
    gender: string;
    country: string;
    mobile?: string;
    post_code: string;
    current_qualifications: UserQualification[];
    future_qualifications: UserQualification[];
    year_group: number;
    career_phase: string;
    current_subjects: number[];
    future_subjects: number[];
    current_learning_providers: number[];
    future_learning_providers: number[];
    email: string;
    last_updated?: string;
    questions_answered?: number;
    ageGroup: number | undefined;
    userType: string | undefined;
    securityToken: string;
    segments: {
        main: string[];
        super: string[];
        high: string[];
        medium: string[];
        low: string[];
        all: string[];
        adverts: string[];
    };
}

export const initialState: UserReducerState = {
    userId: 0,
    loading: true,
    userGroupId: 1,
    userGroups: [],
    first_name: '',
    last_name: '',
    username: '',
    avatar: '',
    dob: '',
    age: 'ukg',
    gender: '',
    country: '',
    mobile: '',
    post_code: '',
    current_qualifications: [],
    future_qualifications: [],
    year_group: 0,
    career_phase: '',
    current_subjects: [],
    future_subjects: [],
    current_learning_providers: [],
    future_learning_providers: [],
    email: '',
    last_updated: '',
    questions_answered: 0,
    ageGroup: undefined,
    userType: 'guest',
    securityToken: 'guest',
    segments: {
        main: [],
        super: [],
        high: [],
        medium: [],
        low: [],
        all: [],
        adverts: []
    }
};

export default function userReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case UPDATE_USER_ID:
            return {
                ...state,
                userId: action.payload.userId,
                userType: action.payload.userId > 0 ? 'member' : 'guest'
            };
        case UPDATE_USERGROUP_ID:
            return {
                ...state,
                userGroupId: action.payload.userGroupId
            };
        case UPDATE_USER_DETAILS:
            const ageGroup = action.payload.dob
                ? getAgeGroupFromDOB(action.payload.dob)
                : undefined;
            const age = action.payload.dob ? getUserAge(action.payload.dob) : 'ukg';
            return { ...state, ...action.payload, ageGroup: ageGroup, age: age };
        case UPDATE_SECURITY_TOKEN:
            return { ...state, securityToken: action.payload.securityToken };
        case SET_USER_SEGMENTS:
            return {
                ...state,
                segments: action.payload
            };
        default:
            return state;
    }
}

import { AnyAction } from 'redux';
import { UPDATE_SAVED_REPLIES, REMOVE_SAVED_REPLY, CLEAR_SAVED_REPLIES } from './actions';
import type { RemirrorJSON } from '@remirror/core';

interface ReplyReducerState {
    [postId: string]: RemirrorJSON;
}

export const initialState: ReplyReducerState = {};

export default function replyReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case UPDATE_SAVED_REPLIES:
            return { ...state, [action.payload.postId]: action.payload.replyContent };
        case REMOVE_SAVED_REPLY:
            // remove the saved reply if it exists
            if (state[action.payload.postId]) {
                delete state[action.payload.postId];
            }
            return { ...state };
        case CLEAR_SAVED_REPLIES:
            // clear all saved replies
            for (const prop of Object.getOwnPropertyNames(state)) {
                delete state[prop];
            }
            return { ...state };
        default:
            return state;
    }
}

import { ErrorInfo, ReactNode } from 'react';
import { ErrorBoundary } from '../Datadog';

interface Props {
    children?: ReactNode;
    errorComponent?: ReactNode;
}

export default function (props: Props) {
    return (
        <ErrorBoundary
            fallback={(error: Error, errorInfo: ErrorInfo) => {
                return <>{props.errorComponent}</> || null;
            }}
        >
            {props.children}
        </ErrorBoundary>
    );
}

import { gql } from 'graphql-tag';

export const CREATE_LEAD = gql`
    mutation Lead_create($leadTypeCode: String!, $learningProviderId: Int!, $enquiry: String!, $route: String, $urlPath: String) {
        lead_create(
            lead_type_code: $leadTypeCode
            learning_provider_id: $learningProviderId
            learning_provider_source: "IED"
            enquiry: $enquiry
            website_source: "TSR"
            route: $route
            url_path: $urlPath
        ) {
            valid
            error
        }
    }
`;

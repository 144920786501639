import { Fragment } from 'react';
import useTranslation from '../../../hooks/useTranslation';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../client';
import { useLocation } from 'react-router-dom';
import { showModal } from '../../../store/modals/actions';

// Custom Components
import LoginButton from '../../../components/Buttons/LoginButton';
import { recordVirtualPageView } from '@thestudentroom/datalayer';
import {
    HeaderButtons,
    BurgerMenuContainer,
    StyledToolbar,
    HeaderSearchBarContainer,
    UserMenuContainer,
    Desktop,
    Mobile,
    StyledSignUpButton
} from './styles';
import HeaderLogo from './logo';
import BurgerMenu from '../Navigation/BurgerMenu';
import UserMenus from './UserMenus/index';
import AskAQuestion from '../../../components/AskAQuestion';
import GlobalSearchBar from '../../../components/SearchBar/global';
import SearchBarDropdown from '../../../components/SearchBar/SearchBarDropDown';

interface HeaderToolbarProps {
    searchOpen: boolean;
    searchToggle: Function;
}

export default function HeaderToolbar({
    searchOpen,
    searchToggle
}: HeaderToolbarProps) {
    const { t } = useTranslation();
    const reduxDispatch = useDispatch();
    const userId: number = parseInt(useSelector((state: RootState) => state.user.userId));

    // TODO - How do we get this?
    // const forumId = breadCrumbs && breadCrumbs.forums ? breadCrumbs.forums[breadCrumbs.forums.length-1].id : null;

    const openRegModal = () => {
        setTimeout(() => {
            recordVirtualPageView('/virtual/header-bar/register?action=click');
            setTimeout(() => {
                reduxDispatch(showModal(true, 'headersignup', {}, true));    
            }, 0);
        }, 0);
    };

    const location = useLocation();
    const disableSearch = location?.pathname.startsWith('/search');

    return (
        <StyledToolbar id={'toolbar'}>
            <HeaderLogo key={'header-logo'} />
            <HeaderButtons key={'header-buttons'}>
                <AskAQuestion key={'ask-a-question'} />

                <UserMenuContainer>
                    <Desktop>
                        {!disableSearch && (
                            <HeaderSearchBarContainer>
                                <GlobalSearchBar />
                            </HeaderSearchBarContainer>
                        )}
                    </Desktop>
                    <Mobile>
                        <SearchBarDropdown
                            open={searchOpen}
                            searchToggle={searchToggle}
                            disabled={disableSearch}
                        />
                    </Mobile>
                    {userId > 0 ? (
                        <UserMenus />
                    ) : (
                        <Fragment key="header-login-buttons">
                            <LoginButton key={'login-button'} />
                            <StyledSignUpButton
                                key={'sign-up-button'}
                                aria-label="Sign up"
                                onClick={openRegModal}
                            >
                                {t('buttons.signup')}
                            </StyledSignUpButton>
                        </Fragment>
                    )}

                    <BurgerMenuContainer key={'burger-menu-container'}>
                        <BurgerMenu />
                    </BurgerMenuContainer>
                </UserMenuContainer>
            </HeaderButtons>
        </StyledToolbar>
    );
}

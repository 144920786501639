import styled from '@emotion/styled';
import { css } from '@emotion/react';

// MUI Components
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Badge from '@mui/material/Badge';

// Components
import Link from '../../../../../components/Link';
import { withTransientProps } from '../../../../../helpers/transientStylingProp';

// Icons
import { MdCircle } from 'react-icons/md';

export const MarkAsRead = styled.p`
    color: ${(props) => props.theme.palette.success.main};
    margin: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.008px;
    cursor: pointer;
`;

export const SeeAll = styled(Link)`
    color: ${(props) => props.theme.palette.success.main};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.84px;
    text-transform: none;
    align-self: center;
`;

export const MenuContainer = styled(Box)`
    display: inline-block;
    max-width: fit-content;
    margin-right: ${(props) => props.theme.spacing(1)};
    margin-left: ${(props) => props.theme.spacing(1)};
`;

export const MenuDetails = styled(
    Box,
    withTransientProps
)<{ $menuName: string }>(
    ({ theme, $menuName }) => css`
        display: inline-flex;
        flex-direction: row;
        padding: ${$menuName === 'usermenu' ? theme.spacing(0, 1, 1) : theme.spacing(1)};
        margin-top: ${$menuName === 'usermenu' ? theme.spacing(1) : theme.spacing(0)};

        @media (max-width: 500px) {
            padding: ${$menuName === 'usermenu' ? '1px' : theme.spacing(1)};
        }
    `
);

export const MenuFooter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: ${(props) => props.theme.spacing(3)};
    justify-content: center;
    min-height: 30px;
`;

export const MenuHeader = styled.div<{ menuName: string }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: ${(props) => props.theme.spacing(0, 2)};
    padding: ${(props) => props.menuName === 'usermenu' ? props.theme.spacing(0, 0, 2, 0) : props.theme.spacing(2, 0)};
    justify-content: space-between;
    border-bottom: ${(props) => props.menuName === 'usermenu' ? `1px solid ${props.theme.grey.main}` : 'none'};
`;

export const MenuInner = styled.div<{ menuName: string }>`
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: ${(props) => props.theme.spacing(2)};
    background-color: ${(props) => props.theme.palette.primary.dark};
    border-radius: 6px;
    -webkit-box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.51);
    box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.51);
    width: 375px;
    overflow: hidden;

    @media (max-width: 982px) {
        width: 100vw;
        margin-top: ${(props) => (props.menuName == 'usermenu' ? props.theme.spacing(1) : '8px')};
    }
`;

export const MenuPopper = styled(Popper)`
    max-height: calc(100vh - 150px);
    overflow-y: auto;
    border-radius: 6px;
    -webkit-box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.51);
    box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.51);
    z-index: 600;
`;

export const MenuSection = styled.div<{ menuName: string }>`
    display: flex;
    flex-direction: column;
    padding: ${(props) => props.theme.spacing(0, 2)};
    overflow-y: auto;
    max-height: ${props => props.menuName === 'notifications' ? 'calc(50vh - 50px)' : 'none'};
    scrollbar-color: ${(props) => props.theme.palette.primary.main}50 ${(props) => props.theme.lilac.light};
    scrollbar-width: thin;
`;

export const MenuTitle = styled.h3`
    color: ${(props) => props.theme.palette.primary.contrastText};
    margin: 0;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.008px;
`;

export const MenuItemTitle = styled.p<{ unread: boolean }>`
    color: ${(props) => props.theme.text.link};
    font-size: 14px;
    font-weight: ${(props) => (props.unread ? '700' : '400')};
    line-height: 24px;
    letter-spacing: 0.008px;
    margin: ${(props) => props.theme.spacing(0)};
`;

export const MenuItem = styled.div<{ clickable: boolean }>`
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid ${(props) => props.theme.grey.main};
    padding: ${(props) => props.theme.spacing(2, 0)};
    justify-content: space-between;
    gap: ${(props) => props.theme.spacing(2)};
    cursor: ${(props) => props.clickable && 'pointer'};
    
    &:hover {
        ${MenuItemTitle} {
            text-decoration: underline;
        }
    }
`;

export const MenuItemLink = styled(Link)`
    &:hover {
        text-decoration: none;
    }
`;

export const MenuItemDetails = styled.p`
    color: ${(props) => props.theme.palette.primary.contrastText};
    ${(props) => props.theme.typography.caption};
    margin: ${(props) => props.theme.spacing(1, 0, 0, 0)};

    &:hover {
        text-decoration: none;
    }
`;

export const MenuItemTimestamp = styled.div`
    display: flex;
    flex-direction: row;
    align-self: baseline;
    align-items: baseline;
    margin: 0;
    gap: ${(props) => props.theme.spacing(2)};
    ${(props) => props.theme.typography.caption};
    white-space: nowrap;
    line-height: 24px;

    &:hover {
        text-decoration: none;
    }
`;

export const UnreadIcon = styled(MdCircle)`
    color: ${(props) => props.theme.palette.info.main};
    width: 10px;
    height: 10px;
    align-self: baseline;
`;

export const MenuItemContent = styled.div`
    display: flex;
    flex-direction: row;
`;

export const MenuItemText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const MenuItemIcon = styled.div<{ unread: boolean }>`
    color: ${(props) => props.theme.palette.primary.contrastText};
    margin-right: ${(props) => props.theme.spacing(2)};
    margin-top: ${(props) => props.theme.spacing(1)};
    align-self: center;    
    opacity: ${(props) => (props.unread ? '1' : '0.6')};
`;

export const MenuBadge = styled(Badge)`
    color: ${(props) => props.theme.palette.primary.contrastText};
    cursor: default;
`;

export const PMFooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
`;

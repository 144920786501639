import styled from '@emotion/styled';
import { Dialog } from '@mui/material';
import Box from '@mui/material/Box';
import { withTransientProps } from '../../helpers/transientStylingProp';
import { css } from '@emotion/react';

export const LayoutMain = styled.div`
    min-height: 100%;
    background-color: ${(props) => props.theme.background};
`;

export const LayoutInner = styled.div`
    display: flex;
    justify-content: center;
    max-width: 100%;
    gap: ${(props) => props.theme.spacing(5)};
    box-sizing: border-box;

    @media (min-width: 671px) {
        padding-top: ${(props) => props.theme.spacing(5)};
    }

    @media (min-width: 1740px) {
        justify-content: space-between;
    }
`;

export const SidePanel = styled.div`
    display: none;
    @media (min-width: 1650px) {
        display: flex;
        flex-grow: 1;
        max-width: calc((100vw - 1350px) / 2);
        overflow: hidden;
    }

    a {
        position: fixed;
        width: 220px;
        height: 900px;
    }
`;

export const LeftSidePanel = styled(SidePanel)`
    justify-content: flex-end;
`;

export const RightSidePanel = styled(SidePanel)`
    justify-content: flex-start;
`;

export const LayoutContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing(4)};
    padding: ${(props) => props.theme.spacing(2, 2, 5)};
    background-color: ${(props) => props.theme.background};
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    width: 100%;

    @media (min-width: 1350px) {
        width: 1350px;
    }

    @media (min-width: 1650px) {
        padding: 0;
    }

    @media (min-width: 671px) {
        padding: ${(props) => props.theme.spacing(0, 5, 5)};
    }
`;

export const FullWidthCenteredContainer = styled.div`
    ${(props) => props.theme.maxWidth};
    margin: ${(props) => props.theme.spacing(0, 'auto')};

    @media (min-width: 981px) {
        width: 100%;
    }
`;

export const LayoutContentInner = styled(FullWidthCenteredContainer)`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: repeat(4, auto);
    grid-column-gap: ${(props) => props.theme.spacing(5)};
    grid-row-gap: ${(props) => props.theme.spacing(5)};
    box-sizing: border-box;
    overflow: hidden;
    overflow-wrap: anywhere;

    @media (min-width: 981px) {
        grid-template-columns: auto 300px;
        grid-template-rows: repeat(3, auto);
    }
`;

export const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing(5)};
    box-sizing: border-box;
    width: 100%;
    grid-area: 1 / 1 / 2 / 2;
`;

// Below main content
export const PrimaryWidgets = styled.div`
    grid-area: 2 / 1 / 3 / 2;
`;

// Sidebar
export const Sidebar = styled(Box)`
    grid-area: 3 / 1 / 4 / 2;
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing(5)};
    @media (min-width: 981px) {
        grid-area: 1 / 2 / 5 / 3;
    }
`;

// Search Sidebar
export const SearchSidebar = styled(Sidebar)`
    margin: ${(props) => props.theme.spacing(15, 0, 0, 0)};
`;

// Below primary and sidebar
export const SecondaryWidgets = styled.div`
    grid-area: 4 / 1 / 5 / 2;

    @media (min-width: 981px) {
        grid-area: 3 / 1 / 4 / 2;
    }
`;

// Heights are the total height of header leaderboard, toolbar nav and navigation bar
// TODO dynamically calculate height
export const Offset = styled('div')<{ location: string }>`
    min-height: ${(props) =>
        props.location === 'search' ? '270px' : props.location === 'chat' ? '212px' : '190px'};

    @media (min-width: 1111px) and (max-width: 1349px) {
        min-height: ${(props) =>
            props.location === 'search' ? '272px' : props.location === 'chat' ? '229px' : '198px'};
    }

    @media (min-width: 981px) and (max-width: 1110px) {
        min-height: ${(props) =>
            props.location === 'search' ? '272px' : props.location === 'chat' ? '229px' : '206px'};
    }

    @media (min-width: 720px) and (max-width: 980px) {
        min-height: ${(props) =>
            props.location === 'search' ? '272px' : props.location === 'chat' ? '205px' : '191px'};
    }

    @media (max-width: 719px) {
        min-height: ${(props) =>
            props.location === 'search' ? '222px' : props.location === 'chat' ? '169px' : '152px'};
    }

    @media (max-width: 671px) {
        min-height: ${(props) => (props.location === 'search' ? '243px' : '152px')};
    }
`;

// TODO for tug-1229-ad-header-test experiment - remove or make default (instead of Offset) on completion
export const ExperimentOffset = styled('div')<{ location: string }>`
    min-height: ${(props) =>
        props.location === 'search' ? '228px' : props.location === 'chat' ? '170px' : '146px'};

    @media (min-width: 1111px) and (max-width: 1349px) {
        min-height: ${(props) =>
            props.location === 'search' ? '230px' : props.location === 'chat' ? '167px' : '136px'};
    }

    @media (min-width: 981px) and (max-width: 1110px) {
        min-height: ${(props) =>
            props.location === 'search' ? '220px' : props.location === 'chat' ? '167px' : '154px'};
    }

    @media (min-width: 720px) and (max-width: 980px) {
        min-height: ${(props) =>
            props.location === 'search' ? '210px' : props.location === 'chat' ? '143px' : '129px'};
    }

    @media (max-width: 719px) {
        min-height: ${(props) =>
            props.location === 'search' ? '172px' : props.location === 'chat' ? '169px' : '102px'};
    }

    @media (max-width: 671px) {
        min-height: ${(props) => (props.location === 'search' ? '210px' : '102px')};
    }
`;

/* Modal */
export const StyledDialog = styled(
    Dialog,
    withTransientProps
)<{ $isFullScreen: boolean, $aaq: boolean, $leadGen: boolean }>(
    ({ theme, $isFullScreen, $aaq, $leadGen }) => css`
        .MuiDialog-paperWidthFalse {
            max-height: 100%;
            max-width: ${$isFullScreen ? '100%' : `calc(100% - ${theme.spacing(7)}) !important}`};
            overflow-x: hidden !important;
            border-radius: ${$aaq ? theme.spacing(3) : theme.spacing(1)};
            margin: ${$aaq ? theme.spacing(3) : 'unset'};

            @media (max-width: 671px) {
                width: ${$aaq ? '100vw' : 'unset'};
                height: ${$leadGen ? 'unset' : '100%'};
            }
        }
    `
);

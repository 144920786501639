export const runtimeConfig =
    typeof window !== 'undefined'
        ? {
              // client
              appDomain: window.env ? window.env.appDomain : '',
              staticDomain: window.env ? window.env.staticDomain : '',
              graphQLEndpoint: window.env ? window.env.graphQLEndpoint : '',
              googleAnalyticsId:
                  process.env.NODE_ENV == 'test'
                      ? 'test'
                      : window.env
                      ? window.env.googleAnalyticsId
                      : '',
              ga4Id: window.env ? window.env.ga4Id : '',
              tsrTrackerUrl: window.env ? window.env.tsrTrackerUrl : '',
              cookiePrefix: window.env ? window.env.cookiePrefix : '',
              gtmAuth: window.env ? window.env.gtmAuth : '',
              gtmEnv: window.env ? window.env.gtmEnv : '',
              googleClientId: window.env ? window.env.googleClientId : '',
              facebookClientId: window.env ? window.env.facebookClientId : '',
              appleClientId: window.env ? window.env.appleClientId : '',
              datadogClientToken: window.env ? window.env.datadogClientToken : '',
              datadogApplicationId: window.env ? window.env.datadogApplicationId : '',
              datadogSessionMonitor: window.env ? window.env.datadogSessionMonitor : '',
              datadogSessionPercentage: window.env ? window.env.datadogSessionPercentage : '',
              datadogSessionMemberPercentage: window.env
                  ? window.env.datadogSessionMemberPercentage
                  : '',
              datadogSessionReplayPercentage: window.env
                  ? window.env.datadogSessionReplayPercentage
                  : '',
              version: window.env ? window.env.version : '',
              growthBookHost: window.env ? window.env.growthBookHost : '',
              growthBookKey: window.env ? window.env.growthBookKey : '',
              typesenseHost: window.env ? window.env.typesenseHost : '',
              uniGuideDomain: window.env ? window.env.uniGuideDomain : '',
              uniGuideCDNDomain: window.env ? window.env.uniGuideCDNDomain : '',
              pusherKey: window.env ? window.env.pusherKey : '',
              pusherCluster: window.env ? window.env.pusherCluster : '',
              publicDomain: window.env ? window.env.publicDomain : '',
              assetsPublicPath: window.env ? window.env.assetsPublicPath : undefined
          }
        : {
              // server
              appDomain: process.env.APP_DOMAIN || '',
              staticDomain: process.env.REACT_APP_STATIC_DOMAIN || '',
              graphQLEndpoint: process.env.GRAPHQL_ENDPOINT || '',
              googleAnalyticsId: process.env.GOOGLE_ANALYTICS_ID || '',
              ga4Id: process.env.GA4_ID || '',
              tsrTrackerUrl: process.env.EVENT_TRACKER_URL || '',
              cookiePrefix: process.env.COOKIE_PREFIX || '',
              gtmAuth: process.env.GTM_AUTH || '',
              gtmEnv: process.env.GTM_ENV || '',
              googleClientId: process.env.GOOGLE_CLIENT_ID || '',
              facebookClientId: process.env.FACEBOOK_CLIENT_ID || '',
              appleClientId: process.env.APPLE_CLIENT_ID || '',
              datadogClientToken: process.env.DATADOG_CLIENT_TOKEN || '',
              datadogApplicationId: process.env.DATADOG_APPLICATION_ID || '',
              datadogSessionMonitor: process.env.DATADOG_SESSION_MONITOR || '',
              datadogSessionPercentage: process.env.DATADOG_SESSION_PERCENTAGE || '',
              datadogSessionMemberPercentage: process.env.DATADOG_SESSION_MEMBER_PERCENTAGE || '',
              datadogSessionReplayPercentage: process.env.DATADOG_SESSION_REPLAY_PERCENTAGE || '',
              version: process.env.APP_VERSION || '',
              growthBookHost: process.env.GROWTHBOOK_HOST || '',
              growthBookKey: process.env.GROWTHBOOK_CLIENT_KEY || '',
              typesenseHost: process.env.TYPESENSE_HOST || '',
              uniGuideDomain: process.env.UNIGUIDE_DOMAIN || '',
              uniGuideCDNDomain: process.env.UNIGUIDE_CDN_DOMAIN || '',
              pusherKey: process.env.PUSHER_APP_KEY || '',
              pusherCluster: process.env.PUSHER_APP_CLUSTER || '',
              publicDomain: process.env.PUBLIC_DOMAIN,
              assetsPublicPath: process.env.ASSETS_PUBLIC_PATH
          };

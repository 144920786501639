import styled from '@emotion/styled';
import { MdMenu } from 'react-icons/md';
import Box from '@mui/material/Box';

export const BurgerMenuIcon = styled(MdMenu)`
    color: ${(props) => props.theme.white};

    @media (min-width: 761px) {
        margin-left: ${(props) => props.theme.spacing(2)};
    }
`;

export const DrawerBox = styled(Box)`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: ${(props) => props.theme.palette.primary.main};
    width: 100vw;
    height: 100%;
    padding-top: ${(props) => props.theme.spacing(3)};

    @media (min-width: 671px) {
        width: 50vw;
    }
`;

export const BurgerMenuToolbar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-left: ${(props) => props.theme.spacing(5)};
`;

export const AppButtonContainer = styled.div`
    width: 100%;
    align-self: center;
    flex-shrink: 0;
    padding: ${(props) => props.theme.spacing(5)};

    @media(min-width: 671px) {
        display: none;
    }
`;

export default (dateOfBirth: string) => {
    if (dateOfBirth == 'ukg' || !dateOfBirth) {
        return 'ukg';
    }

    const today = new Date(Date.now());
    const birthDate = new Date(dateOfBirth);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // adjust based on the current month and date
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age.toString();
};

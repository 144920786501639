import { Fragment, useState } from 'react';
import MenuTemplate, { MenuItemTemplate } from '../Template';
import { MenuBadge, MenuTitle } from '../Template/styles';
import { useAppTheme } from '@thestudentroom/lib.themes';
import UserAvatar from '../../../../../components/User/Avatar';
import { MdEmail, MdError, MdExpandMore, MdNotifications } from 'react-icons/md';
import useTranslation from '../../../../../hooks/useTranslation';

interface MenuErrorProps {
    menuName: string;
}

export default function MenuError(props: MenuErrorProps) {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    const BodyContent = (
        <MenuItemTemplate
            itemIcon={<MdError size={'25'} />}
            itemHeading={t(`errors.${props.menuName}-menu`)}
            itemDetails={t('errors.member-toolbar')}
            key={`${props.menuName}-error`}
            unread={true}
        />
    );

    return (
        <MenuTemplate
            MenuToggleContent={
                <MenuBadge badgeContent={0}>
                    <MenuIcon menuName={props.menuName} />
                </MenuBadge>
            }
            MenuHeaderContent={<MenuTitle>{t(`usermenu.${props.menuName}`)}</MenuTitle>}
            MenuBodyContent={BodyContent}
            menuName={props.menuName}
            open={open}
            setOpen={setOpen}
        />
    );
}

function MenuIcon({ menuName }: { menuName: string }) {
    const theme = useAppTheme();
    switch (menuName) {
        case 'private-messages':
            return (
                <MdEmail
                    style={{ color: theme.white, width: '25px', height: '25px' }}
                    data-testid={'pm-error'}
                />
            );
        case 'notifications':
            return (
                <MdNotifications
                    style={{ color: theme.white, width: '25px', height: '25px' }}
                    data-testid={'notification-error'}
                />
            );
        case 'usermenu':
            return (
                <Fragment key={'usermenu-header-content'}>
                    <UserAvatar
                        username={'Error'}
                        imageUrl={'images/miscellaneous/unknown.gif'}
                        sx={{
                            width: '35px',
                            height: '35px',
                            marginRight: theme.spacing(3),
                            animation: 'none'
                        }}
                    />
                    <MdExpandMore
                        size={20}
                        color={theme.white}
                        style={{
                            alignSelf: 'center',
                            marginRight: theme.spacing(1)
                        }}
                    />
                </Fragment>
            );
        default:
            return <MdError data-testid={'default-error'} size={'25'} />;
    }
}

import { MouseEvent, useState, useEffect } from 'react';
import useQuery from '../../../hooks/useQuery';
import { MdSearch } from 'react-icons/md';
import SearchBar from '../global';
import Collapse from '@mui/material/Collapse';
import {
    SearchBarDropdownContainer,
    SearchIconButton,
    Dropdown,
    SearchBarContainer
} from './styles';
import { useAppTheme } from '@thestudentroom/lib.themes';

interface SearchBarDropdownProps {
    open: boolean;
    searchToggle: Function;
    disabled: boolean;
}

export default function SearchBarDropdown({
    open,
    searchToggle,
    disabled
}: SearchBarDropdownProps) {
    const theme = useAppTheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const toggleSearchDropdown = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        searchToggle();
    };

    const canBeOpen = open && Boolean(anchorEl);
    const id = canBeOpen ? 'search-bar-dropdown' : undefined;

    const query = useQuery();
    const threadId = parseInt(query.get('t') || '0');

    // close search bar when navigating to new page (ignoring pages within a thread)
    useEffect(() => {
        if (open) searchToggle();
    }, [threadId]);

    return (
        <SearchBarDropdownContainer>
            <SearchIconButton
                open={open}
                aria-label="Open search"
                onClick={toggleSearchDropdown}
                disableTouchRipple={true}
                disabled={disabled}
            >
                <MdSearch size={30} color={theme.palette.primary.contrastText} />
            </SearchIconButton>
            <Dropdown
                aria-label={'Search bar dropdown'}
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement={'bottom'}
                keepMounted
                transition
                disablePortal={true}
            >
                {({ TransitionProps }) => (
                    <Collapse {...TransitionProps} timeout={250}>
                        <SearchBarContainer>
                            {open && <SearchBar autoFocus={true} />}
                        </SearchBarContainer>
                    </Collapse>
                )}
            </Dropdown>
        </SearchBarDropdownContainer>
    );
}

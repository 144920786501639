import styled from '@emotion/styled';
import HoverLink from './HoverMenuLink';
import items from '../items';

const NavigationContainer = styled.nav`
    width: 100%;
    background-color: ${(props) => props.theme.palette.primary.main};
    transition: width 1s;
    display: flex;
    justify-content: center;
`;

const NavigationContainerInner = styled.ul`
    display: flex;
    ${(props) => props.theme.maxWidth};
    justify-content: space-between;
    margin: ${(props) => props.theme.spacing(2, 0, 0)};
    padding: ${(props) => props.theme.spacing(0)};
`;

export default function Navigation() {
    return (
        <NavigationContainer data-cy="navigation">
            <NavigationContainerInner>
                {items.map((item, index) => (
                    <HoverLink key={`hover-menu-${index}`} {...item} />
                ))}
            </NavigationContainerInner>
        </NavigationContainer>
    );
}

import { useState, KeyboardEvent, MouseEvent } from 'react';
import { MdClose } from 'react-icons/md';
import Drawer from '@mui/material/Drawer';
import BurgerMenuLink from './BurgerMenuLink';
import IconButton from '@mui/material/IconButton';
import { recordClick } from '@thestudentroom/datalayer';
import items from '../items';
import { useAppTheme } from '@thestudentroom/lib.themes';
import { MdOutlineAod as PhoneIcon } from "react-icons/md";
import redirectToAppStore from '../../../../helpers/redirectToAppStore';
import useTranslation from '../../../../hooks/useTranslation';
import { PrimaryOutlinedButton } from '@thestudentroom/lib.themes';
import {
    BurgerMenuIcon,
    DrawerBox,
    BurgerMenuToolbar,
    AppButtonContainer
} from './styles';

export default function BurgerMenu() {
    const { t } = useTranslation();
    const theme = useAppTheme();
    const [open, setOpen] = useState(false);

    const toggleDrawer = (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setTimeout(() => {
            recordClick({
                group: 'navigating',
                section: 'header',
                subsection: 'main-navigation',
                item: 'burger-menu',
                action: open ? 'open' : 'close'
            });
    
            setOpen(open);
        },0);
    };

    return (
        <>
            <BurgerMenuIcon size={30} onClick={toggleDrawer(true)} data-testid="hamburger-icon" />
            <Drawer
                anchor={'right'}
                open={open}
                onClose={toggleDrawer(false)}
                aria-label="mobile navigation menu"
                ModalProps={{
                    keepMounted: true,
                    disablePortal: true
                }}
                data-testid="burger-menu"
            >
                <DrawerBox>
                    <BurgerMenuToolbar>
                        {/* Close button */}
                        <IconButton
                            data-testid="close-icon"
                            onClick={toggleDrawer(false)}
                            sx={{
                                color: theme.white,
                                margin: theme.spacing(0, 3),
                                height: '32px'
                            }}
                            disableRipple={true}
                        >
                            <MdClose size={24} />
                        </IconButton>
                    </BurgerMenuToolbar>

                    {/* Menu links */}
                    {open && items.map((item, index) => <BurgerMenuLink key={index} {...item} />)}

                    <AppButtonContainer>
                        <PrimaryOutlinedButton
                            onClick={redirectToAppStore}
                            startIcon={<PhoneIcon size={20} />}
                            $isOnDark
                            fullWidth
                        >
                            {t('buttons.get-the-app')}
                        </PrimaryOutlinedButton>
                    </AppButtonContainer>
                </DrawerBox>
            </Drawer>
        </>
    );
}

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../client';
import { updateUserDetails } from '../store/user/actions';

export default function ({ children }: { children: React.ReactChild }) {
    const dispatch = useDispatch();
    const userId = useSelector((state: RootState) => state.user.userId);

    useEffect(() => {
        if (parseInt(userId) === 0) {
            dispatch(
                updateUserDetails(
                    false,
                    1,
                    [],
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    '',
                    [],
                    [],
                    0,
                    '',
                    [],
                    [],
                    [],
                    [],
                    '',
                    '',
                    '',
                    '',
                    0
                )
            );
        }
    }, [userId]);

    return <>{children}</>;
}

/* React */
import { MouseEventHandler, useRef, ReactNode, useEffect } from 'react';

/* Helpers */
import ConditionalWrapper from '../../../../../helpers/ConditionalWrapper';

/* Material UI */
import Fade from '@mui/material/Fade';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import useMediaQuery from '@mui/material/useMediaQuery';
import useScrollTrigger from '@mui/material/useScrollTrigger';

/* Datalayer */
import { recordClick } from '@thestudentroom/datalayer';

/* Custom Styles */
import {
    MenuContainer,
    MenuDetails,
    MenuFooter,
    MenuHeader,
    MenuInner,
    MenuPopper,
    MenuSection,
    MenuItem,
    MenuItemTitle,
    MenuItemLink,
    MenuItemDetails,
    MenuItemContent,
    MenuItemIcon,
    UnreadIcon,
    MenuItemTimestamp,
    MenuItemText
} from './styles';

interface MenuTemplateProps {
    MenuToggleContent: ReactNode;
    MenuHeaderContent?: ReactNode;
    MenuBodyContent: ReactNode;
    MenuFooterContent?: ReactNode;
    menuName: string;
    open: string | boolean;
    setOpen: Function;
    menuSectionRef?: React.RefObject<HTMLDivElement>;
}

export default function MenuTemplate(props: MenuTemplateProps) {
    const trigger = useScrollTrigger();
    const open = props.open === props.menuName;
    const isDesktop = useMediaQuery('(min-width:981px)');
    const anchorRef = useRef(null);

    const handleHover = (openMenu: boolean) => {
        if (!isDesktop) return; // hover to open on desktop only

        props.setOpen(openMenu ? props.menuName : false);

        recordClick({
            group: props.menuName,
            section: 'header',
            subsection: `${props.menuName} menu`,
            item: 'toggle-menu',
            action: openMenu ? 'open' : 'close'
        });
    };

    const handleClick = () => {
        if (isDesktop) return; // click to open on mobile only

        props.setOpen(!open ? props.menuName : false);

        recordClick({
            group: props.menuName,
            section: 'header',
            subsection: `${props.menuName} menu`,
            item: 'toggle-menu',
            action: !open ? 'open' : 'close'
        });
    };

    const handleClickAway = () => {
        // clicks are handled for mobile only
        if (isDesktop) return;

        if (open) {
            props.setOpen(false);

            recordClick({
                group: props.menuName,
                section: 'header',
                subsection: `${props.menuName} menu`,
                item: 'toggle-menu',
                action: 'close'
            });
        }
    };

    // if the user scrolls, then close menus
    useEffect(() => {
        if (trigger) {
            props.setOpen(false);
        }
    }, [trigger]);

    return (
        <ClickAwayListener onClickAway={handleClickAway} touchEvent="onTouchEnd">
            <MenuContainer
                key={`${props.menuName}-menu-container`}
                onMouseEnter={() => handleHover(true)}
                onMouseLeave={() => handleHover(false)}
                data-cy={`${props.menuName}-menu-item`}
                ref={anchorRef}
            >
                <MenuDetails
                    key={`${props.menuName}-menu-details`}
                    onClick={() => handleClick()}
                    data-testid={`${props.menuName}-menu-toggle`}
                    $menuName={props.menuName}
                >
                    {props.MenuToggleContent}
                </MenuDetails>

                <MenuPopper
                    key={`${props.menuName}-menu-popper`}
                    id={`${props.menuName}-menu-popper`}
                    data-testid={`${props.menuName}-menu-popper`}
                    open={open}
                    anchorEl={anchorRef.current}
                    keepMounted
                    disablePortal={true}
                    placement={'bottom-end'}
                >
                    <Fade in={open} key={`${props.menuName}-menu-fade`}>
                        <MenuInner
                            key={`${props.menuName}-menu-inner`}
                            data-testid={`${props.menuName}-menu-inner`}
                            menuName={props.menuName}
                        >
                            <MenuHeader menuName={props.menuName} key={`${props.menuName}-menu-name`}>
                                {props.MenuHeaderContent}
                            </MenuHeader>

                            <MenuSection menuName={props.menuName} ref={props.menuSectionRef} key={`${props.menuName}-menu-section`}>
                                {props.MenuBodyContent}
                            </MenuSection>

                            {props.MenuFooterContent && (
                                <MenuFooter key={`${props.menuName}-menu-footer`}>{props.MenuFooterContent}</MenuFooter>
                            )}
                        </MenuInner>
                    </Fade>
                </MenuPopper>
            </MenuContainer>
        </ClickAwayListener>
    );
}

interface MenuItemTemplateProps {
    itemIcon: ReactNode;
    itemHeading: string;
    itemDetails: string;
    itemTimestamp?: string;
    url?: string;
    onClick?: MouseEventHandler<HTMLParagraphElement>;
    unread: boolean;
}

export function MenuItemTemplate(props: MenuItemTemplateProps) {
    return (
        <ConditionalWrapper
            condition={!!props.url}
            wrapper={(children: ReactNode) => (
                <MenuItemLink url={props.url ?? ''}>{children}</MenuItemLink>
            )}
        >
            <MenuItem onClick={props.onClick ? props.onClick : () => {}} clickable={!!props.onClick}>
                <MenuItemContent>
                    {props.itemIcon && (
                        <MenuItemIcon unread={props.unread}>
                            {props.itemIcon}
                        </MenuItemIcon>
                    )}

                    <MenuItemText>
                        <MenuItemTitle unread={props.unread}>
                            {props.itemHeading}
                        </MenuItemTitle>

                        {props.itemDetails && <MenuItemDetails>{props.itemDetails}</MenuItemDetails>}
                    </MenuItemText>
                </MenuItemContent>

                <MenuItemTimestamp>
                    {props.unread && <UnreadIcon />}
                    {props.itemTimestamp}
                </MenuItemTimestamp>
            </MenuItem>
        </ConditionalWrapper>
    );
}

export default {
    typePolicies: {
        User: {
            // This prevents "anonymous" users being cached - they share the same "userid" but have different "usernames"
            keyFields: ['id', 'username']
        },
        UserGroup: {
            // This prevents usergroup settings being cached between users,
            // e.g. 2 users have the same usergroup, but one has it primary and the other doesnt
            keyFields: ['id', 'primary', 'display']
        }
    }
};

import type { RemirrorJSON } from '@remirror/core';

export const UPDATE_SAVED_REPLIES = 'UPDATE_SAVED_REPLIES';
export const REMOVE_SAVED_REPLY = 'REMOVE_SAVED_REPLY';
export const CLEAR_SAVED_REPLIES = 'CLEAR_SAVED_REPLIES';

export function updateSavedReplies(postId: string, replyContent: RemirrorJSON) {
    return {
        type: UPDATE_SAVED_REPLIES,
        payload: {
            postId,
            replyContent
        }
    };
}

export function removeSavedReply(postId: string) {
    return {
        type: REMOVE_SAVED_REPLY,
        payload: {
            postId
        }
    };
}

export function clearSavedReplies() {
    return {
        type: CLEAR_SAVED_REPLIES,
        payload: {}
    };
}

import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import graphQlLink from './link';
import cachePolicy from './cachePolicy';

export default function getApolloClient(store: any): ApolloClient<NormalizedCacheObject> {
    return new ApolloClient({
        link: graphQlLink(store),
        cache: new InMemoryCache(cachePolicy).restore((window as any).__APOLLO_STATE__),
        credentials: 'include'
    });
}

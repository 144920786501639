import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    maxWidth: 'width: 100%; @media(min-width: 1350px) { max-width: 1350px; }',
    spacing: 3,
    // Old theme colors - to be replaced throughout
    palette: {
        // Blue
        primary: {
            main: '#4bc2f1',
            light: '#e1f5fd',
            dark: '#44A0D4',
            contrastText: '#ffffff'
        },
        // Yellow
        secondary: {
            main: '#ffb200'
        },
        // Green
        success: {
            main: '#43ad24'
        },

        text: {
            primary: '#181C1E',
            secondary: '#3E484D'
        },

        background: {
            paper: '#FFFFFF',
            default: '#FBFCFE'
        }
    },

    // Navigation (including some legacy colors)
    hoverMenu: {
        maxWidth: 'width: 100%; @media(min-width: 981px) { max-width: 1080px; }'
    },
    navigation: {
        desktopNav: '#4BC2F1', // Primary blue
        desktopNavHover: '#44A0D4',
        burgerNav1: '#44A0D4',
        burgerNav2: '#328DCD',
        burgerNav3: '#2B81C4',
        footer: '#4BC2F1', // Primary blue
        footerCopyright: '#44A0D4'
    },

    // New design system
    typography: {
        fontFamily: 'Arial, san-serif',
        fontSize: 14,

        h1: {
            fontFamily: 'canada-type-gibson, sans-serif',
            fontSize: '96px',
            fontWeight: 500,
            lineHeight: '144px'
        },
        h2: {
            fontFamily: 'canada-type-gibson, sans-serif',
            fontSize: '60px',
            fontWeight: 500,
            lineHeight: '90px'
        },
        h3: {
            fontFamily: 'canada-type-gibson, sans-serif',
            fontSize: '48px',
            fontWeight: 400,
            lineHeight: '72px'
        },
        h4: {
            fontFamily: 'canada-type-gibson, sans-serif',
            fontSize: '34px',
            fontWeight: 400,
            lineHeight: '51px'
        },
        h5: {
            fontFamily: 'canada-type-gibson, sans-serif',
            fontSize: '24px',
            fontWeight: 500,
            lineHeight: '30px'
        },
        h6: {
            fontFamily: 'canada-type-gibson, sans-serif',
            fontSize: '20px',
            fontWeight: 500,
            lineHeight: '30px'
        },
        subtitle1: {
            fontFamily: 'Arial, san-serif',
            fontSize: '16px',
            fontWeight: 'bold',
            lineHeight: '24px'
        },
        subtitle2: {
            fontFamily: 'Arial, san-serif',
            fontSize: '14px',
            fontWeight: 'bold',
            lineHeight: '21px'
        },
        body1: {
            fontFamily: 'Arial, san-serif',
            fontSize: '16px',
            fontWeight: 'normal',
            lineHeight: '24px'
        },
        body2: {
            fontFamily: 'Arial, san-serif',
            fontSize: '14px',
            fontWeight: 'normal',
            lineHeight: '21px'
        },
        button: {
            fontFamily: 'Arial, san-serif',
            fontSize: '14px',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            letterSpacing: '0.84px',
            lineHeight: '21px'
        },
        caption: {
            fontFamily: 'Arial, san-serif',
            fontSize: '12px',
            fontWeight: 'normal',
            lineHeight: '18px'
        },
        overline: {
            fontFamily: 'Arial, san-serif',
            fontSize: '10px',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            lineHeight: '15px',
            letterSpacing: '1.5px'
        }
    },
    shape: {
        borderRadius: 4
    },
    text: {
        primary: '#181C1E',
        secondary: '#3E484D',
        tertiary: '#6E777C',
        richBlue: '#1F1F5E',
        darkGray: '#272727',
        link: '#017FB0',
        headline1: {
            fontFamily: 'canada-type-gibson, sans-serif',
            fontSize: '96px',
            fontWeight: 500,
            lineHeight: '144px'
        },
        headline2: {
            fontFamily: 'canada-type-gibson, sans-serif',
            fontSize: '60px',
            fontWeight: 500,
            lineHeight: '90px'
        },
        headline3: {
            fontFamily: 'canada-type-gibson, sans-serif',
            fontSize: '48px',
            fontWeight: 400,
            lineHeight: '72px'
        },
        headline4: {
            fontFamily: 'canada-type-gibson, sans-serif',
            fontSize: '34px',
            fontWeight: 400,
            lineHeight: '51px'
        },
        headline5: {
            fontFamily: 'canada-type-gibson, sans-serif',
            fontSize: '24px',
            fontWeight: 500,
            lineHeight: '30px'
        },
        headline6: {
            fontFamily: 'canada-type-gibson, sans-serif',
            fontSize: '20px',
            fontWeight: 500,
            lineHeight: '30px'
        },
        TSRPollBody: {
            fontFamily: 'Arial, san-serif',
            fontSize: '16px',
            fontWeight: 400,
            letterSpacing: '0.01px',
            lineHeight: '24px'
        },
        threadPollBody: {
            fontFamily: 'Arial, san-serif',
            fontSize: '14px',
            fontWeight: 400,
            letterSpacing: '0px',
            lineHeight: '21px'
        }
    },
    background: '#FBFCFE',
    white: '#FFFFFF',
    outline: '#DCDDDF',
    button: {
        primaryButton: '#FFB101',
        primaryButtonHover: '#FF844D',
        secondaryButtonHover: '#F0F0F0',
        secondaryHeaderButtonHover: '#44A0D4',
        focusState: '#245FC5'
    },
    link: {
        color: '#181C1E',
        hover: '#181C1E',
        focus: '#245FC5'
    },
    blue: {
        primaryBlue: '#4BC2F1',
        blue2: '#B2EAFF',
        blue3: '#E1F5FD',
        blue4: '#ECF5F8',
        blue5: '#DAE4EA'
    },
    grey: '#A3ACB2',
    green: {
        green1: '#59B117',
        green2: '#94C565'
    },
    red: '#CC0007',
    midPurple: '#BDB1ED',
    lightViolet: '#DED8F6',
    violet: '#7B63DB',
    darkViolet: '#6652B8',
    components: {
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    fontFamily: 'Arial, san-serif',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    letterSpacing: '0.84px',
                    lineHeight: '21px',
                    color: '#3E484D',
                    minWidth: '24px',
                    height: '24px',
                    padding: '0 4px',
                    margin: '0 1px',
                    '&.Mui-selected': {
                        background: '#DAE4EA 0% 0% no-repeat padding-box !important',
                        color: '#3E484D !important',
                        minWidth: '24px',
                        height: '24px'
                    },
                    '&:hover': {
                        backgroundColor: '#F0F0F0'
                    },
                    '&.MuiPaginationItem-ellipsis': {
                        width: '24px'
                    },
                    '&.MuiPaginationItem-ellipsis:hover': {
                        backgroundColor: 'white'
                    }
                }
            }
        }
    }
});

export default theme;

import { useState, useEffect } from 'react';
import UserAvatar from '../../../../../components/User/Avatar';
import useTranslation from '../../../../../hooks/useTranslation';
import getMenuItems from './items';
import {
    UserMenuAccordionDetails,
    UserMenuItem,
    UserMenuLink,
    UsernameAccordion,
    UsernameAccordionSummary,
    UserMenuPersonalisation,
    UsernameContainer,
    UsernameMenuText,
    AvatarContainer,
    StyledIdent
} from './styles';
import { RootState } from '../../../../../client';
import { useSelector, useDispatch } from 'react-redux';
import MenuTemplate from '../Template';
import { Fragment } from 'react';
import { MdExpandMore, MdOutlineSettingsSuggest } from 'react-icons/md';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { showModal } from '../../../../../store/modals/actions';
import { PrimaryOutlinedButton, useAppTheme } from '@thestudentroom/lib.themes';
import { runtimeConfig } from '../../../../../runtimeConfig';
import styled from '@emotion/styled';
import { getAvailableRoutes } from '../../../../../Routes';

const Desktop = styled.div`
    display: none;

    @media (min-width: 501px) {
        display: block;
        margin-left: ${(props) => props.theme.spacing(-1)};
    }
`;

interface MemberToolbarProps {
    open: string | false;
    setOpen: Function;
}

export default function MemberToolbar(props: MemberToolbarProps) {
    const { t } = useTranslation();
    const theme = useAppTheme();
    const reduxDispatch = useDispatch();
    const [expanded, setExpanded] = useState(false);
    const trigger = useScrollTrigger();
    const userDetails = useSelector((state: RootState) => state.user);
    const routes = getAvailableRoutes();

    // if the user scrolls, then close menus
    useEffect(() => {
        if (trigger) {
            setExpanded(false);
        }
    }, [trigger]);

    const updateUrlParams = (url: string) => {
        // if the url contains placeholders, update them
        if (url.match(/{.*}/)) {
            url = url.replace('{logoutHashParam}', userDetails.securityToken);
        }

        return url;
    };

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    const toggleDataCapture = () => {
        props.setOpen(false);
        // Open data capture
        reduxDispatch(showModal(true, 'personalise', {}, true));
    };

    const ToggleContent = (
        <Fragment key={'usermenu-header-content'}>
            {userDetails.avatar === 'images/miscellaneous/unknown.gif' ? (
                <AvatarContainer>
                    <StyledIdent
                        src={runtimeConfig.publicDomain + "/theme/tsr/images/logos/ident-block-colour.svg"}
                    />
                </AvatarContainer>
            ) : (
                <UserAvatar
                    username={userDetails.username ?? ''}
                    imageUrl={userDetails.avatar ?? ''}
                    sx={{
                        width: '30px',
                        height: '30px',
                        marginRight: '0px',
                        animation: 'none',
                        '@media screen and (min-width: 501px)': {
                            width: '35px',
                            height: '35px',
                            marginRight: '5px'
                        }
                    }}
                />
            )}
            <Desktop>
                <MdExpandMore
                    size={20}
                    color={theme.white}
                    style={{
                        alignSelf: 'center',
                        marginTop: '2px'
                    }}
                />
            </Desktop>
        </Fragment>
    );

    const HeaderContent = (
        <Fragment key={'usermenu-header-content'}>
            <UserMenuItem
                key={'usermenu-personalise-my-student-room'}
                title={t('usermenu.personalise-my-student-room')}
                data-testid={t('usermenu.personalise-my-student-room')}
                style={{ width: '30px' }}
            >
                <UserMenuPersonalisation
                    startIcon={
                        <MdOutlineSettingsSuggest size={'20px'} style={{ color: theme.white }} />
                    }
                    aria-label={t('usermenu.personalise-my-student-room')}
                    title={t('usermenu.personalise-my-student-room')}
                    data-testid={t('usermenu.personalise-my-student-room')}
                    onClick={toggleDataCapture}
                />
            </UserMenuItem>
            <UsernameContainer>
                <UsernameMenuText url={`/member.php?u=${userDetails.userId}`}>
                    {userDetails.username ?? ''}
                </UsernameMenuText>
                <UserAvatar
                    username={userDetails.username ?? ''}
                    imageUrl={userDetails.avatar ?? ''}
                    sx={{
                        width: '35px',
                        height: '35px',
                        marginRight: theme.spacing(1),
                        animation: 'none',
                        border: `1px solid ${theme.grey.main}`
                    }}
                />
            </UsernameContainer>
        </Fragment>
    );

    const MenuItems = getMenuItems();

    const BodyContent = (
        <Fragment key={'usermenu-body-content'}>
            {MenuItems.map((item, index) => {
                const url = updateUrlParams(item.url ?? '');

                return item.children ? (
                    // If the item has children then add it an expandable item
                    <UsernameAccordion
                        key={`user-menu-accordion-${index}`}
                        onChange={toggleExpand}
                        expanded={expanded}
                    >
                        <UsernameAccordionSummary
                            expandIcon={<MdExpandMore size={20} color={theme.white} />}
                            aria-controls={`user-menu-content-${index}`}
                            key={`user-menu-summary-${index}`}
                            id={`user-menu-summary-${index}`}
                        >
                            <UserMenuItem
                                key={`user-menu-category-${index}`}
                                title={t(`usermenu.${item.titleKey}`)}
                                data-testid={item.titleKey}
                            >
                                {t(`usermenu.${item.titleKey}`)}
                            </UserMenuItem>
                        </UsernameAccordionSummary>

                        <UserMenuAccordionDetails
                            key={`user-menu-details-${index}`}
                            data-testid={`${item.titleKey}-details`}
                        >
                            {item.children.map((childItem, childIndex) => {
                                const childUrl = updateUrlParams(childItem.url ?? '');

                                return (
                                    <UserMenuLink
                                        key={`user-menu-link-${index}-${childIndex}`}
                                        url={childUrl}
                                        title={t(`usermenu.${childItem.titleKey}`)}
                                        level={1}
                                        onClick={() => {
                                            if (routes.includes(childUrl)) {
                                                props.setOpen(false);
                                            }
                                        }}
                                    >
                                        {t(`usermenu.${childItem.titleKey}`)}
                                    </UserMenuLink>
                                );
                            })}
                        </UserMenuAccordionDetails>
                    </UsernameAccordion>
                ) : (
                    // Otherwise, just add it as a link
                    <UserMenuItem
                        key={`user-menu-category-${index}`}
                        title={t(`usermenu.${item.titleKey}`)}
                        data-testid={item.titleKey}
                    >
                        <UserMenuLink
                            key={`user-menu-link-${index}`}
                            url={url}
                            title={t(`usermenu.${item.titleKey}`)}
                            level={0}
                            onClick={() => {
                                if (routes.includes(url)) {
                                    props.setOpen(false);
                                }
                            }}
                        >
                            {t(`usermenu.${item.titleKey}`)}
                        </UserMenuLink>
                    </UserMenuItem>
                );
            })}
        </Fragment>
    );

    const FooterContent = (
        <PrimaryOutlinedButton
            $isOnDark={true}
            href={`/login.php?do=logout&logouthash=${userDetails.securityToken}`}
            aria-label={t('usermenu.logout')}
        >
            {t('usermenu.logout')}
        </PrimaryOutlinedButton>
    );

    return (
        <Fragment key={'usermenu-container'}>
            <MenuTemplate
                MenuToggleContent={ToggleContent}
                MenuHeaderContent={HeaderContent}
                MenuBodyContent={BodyContent}
                MenuFooterContent={FooterContent}
                menuName={'usermenu'}
                open={props.open}
                setOpen={props.setOpen}
            />
        </Fragment>
    );
}

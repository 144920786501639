import { setUserData } from '@thestudentroom/datalayer';
import { GrowthBook } from '@growthbook/growthbook-react';
import getFisherBucket from './getFisherBucket';
import getMemberType from './User/getMemberType';
import { UserReducerState } from '../store/user/reducer';

export default (gb: GrowthBook | undefined, user: UserReducerState) => {
    const userType = user.userId > 0 ? 'member' : 'guest';
    const memberType = user.userId > 0 ? getMemberType(user.userGroups) : 'guest';
    const segments = Object.fromEntries(
        Object.entries(user.segments).map(([key, value]) => [key + '_user_topics', value])
    );

    setUserData({
        userType: userType,
        userId: user.userId.toString(),
        memberType: memberType,
        userAgeGroup: user.ageGroup,
        testBucket: getFisherBucket(),
        dimension2: userType,
        dimension5: getFisherBucket().toString(),
        dimension8: user.ageGroup ? user.ageGroup.toString() : '',
        ...segments
    });

    if (typeof window !== 'undefined') {
        if (typeof gb !== 'undefined') {
            gb.setAttributes({
                ...gb.getAttributes(),
                userId: user.userId,
                userType: userType,
                memberType: memberType,
                userAgeGroup: user.ageGroup
            });
        }

        // Expose variables globally
        window.MEMBERTYPE = userType; // Weird naming, but this is correct
        window.LOGGEDIN = user.userId > 0 ? true : false;
    }
};

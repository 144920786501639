import { useState, memo, useEffect } from 'react';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useAppTheme } from '@thestudentroom/lib.themes';

// Material UI
import MuiAppBar from '@mui/material/AppBar';
import Fade from '@mui/material/Fade';
import { useScrollTrigger } from '@mui/material';

// Custom Components
import HoverMenu from '../Navigation/HoverMenu';
import HeaderToolbar from './Toolbar';
import HeaderLeaderboard from '../../../components/Ads/HeaderLeaderboard';


export const HoverMenuContainer = styled.div`
    display: none;

    @media (min-width: 981px) {
        display: block;
    }
`;

const AppBar = styled(MuiAppBar)`
    box-shadow: none;
`;

const Header = () => {
    const trigger = useScrollTrigger({ threshold: 50 });
    const [searchOpen, setSearchOpen] = useState(false);
    const location = useLocation();
    const theme = useAppTheme();

    const searchToggle = () => {
        setSearchOpen((prevState) => !prevState);
    };

    // Close the search/aaq dropdowns when navigating between pages
    useEffect(() => {
        setSearchOpen(false);
    }, [location]);

    // Header ad behaviour test
    // Default to false first otherwise it just wont render the bar while figuring out if it's in the test or not
    let ownershipHeaderTest = false;
    ownershipHeaderTest = useFeatureIsOn('tug-1229-ad-header-test');

    return (
        <AppBar position="fixed">
            <HeaderLeaderboard variant={ownershipHeaderTest ? '1' : 'default'} triggered={!ownershipHeaderTest ? true : trigger} />
            <Fade appear={false} in={!trigger}>
                <MuiAppBar
                    position="fixed"
                    sx={{
                        boxShadow: 'none',
                        top: ownershipHeaderTest ? theme.spacing(0) : theme.spacing(15),
                        paddingTop: ownershipHeaderTest ? theme.spacing(3) : theme.spacing(0),
                        '@media screen and (min-width: 720px)': {
                            top: ownershipHeaderTest ? theme.spacing(0) : theme.spacing(25),
                            paddingTop: ownershipHeaderTest ? theme.spacing(12) : theme.spacing(0)
                        }
                    }}
                >
                    <HeaderToolbar
                        searchOpen={searchOpen}
                        searchToggle={searchToggle}
                    />
                    <HoverMenuContainer>
                        <HoverMenu />
                    </HoverMenuContainer>
                </MuiAppBar>
            </Fade>
        </AppBar>
    );
};

export default memo(Header);

import styled from '@emotion/styled';
import Toolbar from '@mui/material/Toolbar';
import { PrimaryButton } from '@thestudentroom/lib.themes';

export const StyledToolbar = styled(Toolbar)`
    justify-content: space-between;
    padding: 0px;
    margin: 0px auto;
    width: 100%;
    align-items: center;
    min-height: 42px !important;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    @media (min-width: 981px) {
        flex-direction: row;
        align-items: flex-start;
        margin: ${(props) => props.theme.spacing(0, 'auto')};
    }

    @media (min-width: 1350px) {
        max-width: 1350px;
    }
`;

export const HeaderButtons = styled.div`
    display: flex;
    align-items: center;
    column-gap: ${(props) => props.theme.spacing(0)};
    row-gap: ${(props) => props.theme.spacing(3)};
    margin: ${(props) => props.theme.spacing(2, 3)};
    padding: ${(props) => props.theme.spacing(0, 2)};
    height: 44px;
    box-sizing: border-box;
    width: 100%;
    justify-content: space-between;

    @media (min-width: 1350px) {
        column-gap: ${(props) => props.theme.spacing(2)};
        margin: ${(props) => props.theme.spacing(1, 0, 1, 0)};
        justify-content: flex-end;
        align-self: flex-end;
    }

    @media (min-width: 981px) and (max-width: 1349px) {
        column-gap: ${(props) => props.theme.spacing(2)};
        row-gap: ${(props) => props.theme.spacing(4)};
        justify-content: flex-end;
        align-self: flex-end;
        margin: ${(props) => props.theme.spacing(0)};
    }
`;

export const HeaderSearchBarContainer = styled.div`
    width: 350px;
`;

export const SearchBarContainer = styled.div`
    display: flex;
    justify-content: center;
    background: ${(props) => props.theme.palette.primary.light};
    z-index: 500;
`;

export const SearchPageSearchBarContainer = styled(SearchBarContainer)<{ headerExperimentOn: boolean }>`
    position: fixed;
    width: 100%;
    left: 0;
    top: ${(props) => props.headerExperimentOn ? '101px' : '151px'};

    @media (min-width: 720px) {
        top: ${(props) => props.headerExperimentOn ? '147px' : '189px'};
    }
`;

export const SearchBoxContainer = styled.div`
    z-index: 1;
    padding: ${(props) => props.theme.spacing(3, 2, 0)};
    ${(props) => props.theme.maxWidth};

    @media (min-width: 671px) {
        padding: ${(props) => props.theme.spacing(5, 5, 0)};
    }
`;

export const BurgerMenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 981px) {
        display: none;
    }
`;

export const UserMenuContainer = styled.div`
    display: flex;
    gap: ${(props) => props.theme.spacing(2)};
    max-height: 44px;
`;

export const Desktop = styled.div`
    display: none;

    @media (min-width: 761px) {
        display: block;
    }
`;

export const Mobile = styled.div`
    display: block;

    @media (min-width: 761px) {
        display: none;
    }
`;

export const StyledSignUpButton = styled(PrimaryButton)`
    align-self: center;
`;

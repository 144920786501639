import { ReactNode } from 'react';

interface ConditionalWrapperProps {
    condition: boolean;
    wrapper: Function;
    children: ReactNode;
}

export default function ConditionalWrapper({
    condition,
    wrapper,
    children
}: ConditionalWrapperProps) {
    return condition ? wrapper(children) : children;
}

import { useInView } from 'react-intersection-observer';
import useDFPSlot from './useDFPSlot';
import { BottomSheetDiv } from './BottomSheetAdvert';
import { useSelector } from 'react-redux';
import { RootState } from '../../client';

interface AdProps {
    className?: string;
    slotId: string;
    adUnit: string;
    sizes: googletag.GeneralSize | googletag.MultiSize | googletag.SingleSize;
    sizeMapping?: googletag.SizeMappingArray;
    targetingArguments?: any;
    lazy?: boolean;
    active?: boolean;
}

// Base Advert to pick up global targeting and merge with ad specific targeting
export default ({
    className,
    slotId,
    adUnit,
    sizes,
    sizeMapping,
    targetingArguments = {},
    lazy = true,
    active = true
}: AdProps) => {
    const advertState = useSelector((state: RootState) => state.adverts);
    const pageTargeting = advertState.pageTargeting;

    const { ref, inView } = useInView({
        rootMargin: '670px 0px',
        triggerOnce: true,
        fallbackInView: true,
        initialInView: lazy ? false : true,
        skip: lazy ? false : true
    });

    if (typeof window !== 'undefined') {
        useDFPSlot({
            path: adUnit,
            size: sizes,
            sizeMapping: sizeMapping,
            targetingArguments: targetingArguments,
            pageTargetingArguments: pageTargeting,
            id: slotId,
            inView: inView,
            active: advertState.ownership_learning_provider ? false : active,
        });
    }

    const slotDiv =
        adUnit === 'tsr-alert-unit-mobile' ? (
            <BottomSheetDiv id={slotId} />
        ) : (
            <div id={slotId}></div>
        );

    let sizeString = '';
    if (sizes == "fluid") {
        sizeString = "__fluid";
    } else if (sizes.length === 2 && typeof sizes[0] === "number" && typeof sizes[1] === "number") {
        sizeString = `__${sizes[0]}x${sizes[1]}`;
    } else {
        sizeString = sizes.map((size: any) => {
            if (size.length === 1 && size[0] == 'fluid') {
                return '__fluid';
            } else {
                return '__' + size.join('x');
            }
        }).join(' ');
    }

    if (advertState.ownership_learning_provider) {
        return null;
    }

    return (
        <div ref={ref} data-freestar-ad={sizeString} className={className}>
            {slotDiv}
        </div>
    );
};

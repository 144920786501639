import useMediaQuery from '@mui/material/useMediaQuery';
import { useAppTheme } from '@thestudentroom/lib.themes';
import { MdClose } from 'react-icons/md';
import IconButton from '@mui/material/IconButton';

interface ReportModalProps {
    open: boolean;
    reportUrl: string;
    onClose: Function;
}

export default function ReportModal(props: ReportModalProps) {
    const theme = useAppTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const currentUrl = typeof window !== 'undefined' ? window.location.href : '';
    const iframeUrl = props.reportUrl + '&parent_url=' + encodeURIComponent(currentUrl);
    return (
        <>
            <IconButton
                aria-label="close"
                onClick={() => {
                    //props on close is a function imported in, run it
                    props.onClose();
                }}
                data-testid="report-modal-close"
                style={{
                    position: 'absolute',
                    right: theme.spacing(3),
                    top: theme.spacing(1),
                    color: theme.palette.primary.contrastText
                }}
            >
                <MdClose />
            </IconButton >
            <iframe
                data-testid={`report-modal`}
                width={fullScreen ? '100%' : '440px'}
                height={fullScreen ? '100%' : '530px'}
                style={{ border: 'none' }}
                src={iframeUrl}
                id="report-modal"
            />
        </>
    );
}

import { AnyAction } from 'redux';
import { UPDATE_FORUM_ID, APP_DOWNLOAD_CHECK } from './actions';

interface ThreadReducerState {
    forumId: number;
    appDownloadCheck: boolean;
}

export const initialState: ThreadReducerState = {
    forumId: 0,
    appDownloadCheck: false
};

export default function threadReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case UPDATE_FORUM_ID:
            return { ...state, forumId: action.payload.forumId };
        case APP_DOWNLOAD_CHECK:
            return { ...state, appDownloadCheck: action.payload.check };
        default:
            return state;
    }
}

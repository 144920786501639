import { AnyAction } from 'redux';
import { SET_PAGE_TARGETING_PARAMS, UPDATE_BOTTOM_SHEET_ACTIVE, SET_OWNERSHIP } from './actions';
import type { LearningProvider } from '../../types/learning_provider';

export interface targetingParamType {
    // Page specific
    p_t: string;
    p_f_id: string;
    p_t_id: string;
    p_t_p: string;
    p_u: string;
    page_version: string;
    page_topics: string[];
    main_page_topics: string[];
}

export interface AdvertState {
    pageTargeting: targetingParamType;
    bottomSheetActive: null | boolean;
    ownership_learning_provider: null | LearningProvider;
}

const initialState: AdvertState = {
    pageTargeting: {
        p_t: '',
        p_f_id: '',
        p_t_id: '',
        p_t_p: '',
        p_u: '',
        page_version: '',

        page_topics: [],
        main_page_topics: []
    },
    bottomSheetActive: null,
    ownership_learning_provider: null
};

export default function advertReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case SET_PAGE_TARGETING_PARAMS:
            return {
                ...state,
                pageTargeting: action.targetingParams
            };
        case UPDATE_BOTTOM_SHEET_ACTIVE:
            return {
                ...state,
                bottomSheetActive: action.active
            };
        case SET_OWNERSHIP:
            let owner = action.learning_provider;
            if (!action.learning_provider?.slug) {
                owner = null;
            }
            return {
                ...state,
                ownership_learning_provider: owner
            };
        default:
            return state;
    }
}

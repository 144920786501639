import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { recordClick } from '@thestudentroom/datalayer';
import SearchBarView from './view';

interface SearchBarProps {
    autoFocus?: boolean;
}

/**
 * Search bar used globally in the header on desktop and dropdown on mobile.
 */
export default function SearchBar({ autoFocus }: SearchBarProps) {
    const navigate = useNavigate();

    const [searchQuery, setSearchQuery] = useState('');

    const submitSearch = (value: string) => {
        const url = `/search/discussions?query=${encodeURIComponent(value)}`;
        recordClick({
            group: 'search',
            section: 'header',
            subsection: 'search-bar',
            type: 'search',
            item: value,
            url: url
        });
        navigate(url);
        setSearchQuery('');
    };

    return (
        <SearchBarView
            autoFocus={autoFocus || false}
            value={searchQuery}
            submitSearch={submitSearch}
            handleChange={(text) => setSearchQuery(text)}
            showSubmitButton={true}
        />
    );
}

import { useEffect } from 'react';
import { useQuery as useGraphQLQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../client';
import { GET_OWN_USER_DETAILS, GET_USER_PROFILE } from '../api/User/graph';
import { updateUserDetails } from '../store/user/actions';
import { useLocation } from 'react-router-dom';

export default function ({ children }: { children: React.ReactChild }) {
    const dispatch = useDispatch();
    const userId = useSelector((state: RootState) => state.user.userId);
    const { pathname } = useLocation();

    const userData = useGraphQLQuery(GET_OWN_USER_DETAILS, {
        variables: { userId: parseInt(userId) },
        errorPolicy: 'all',
        skip: parseInt(userId) <= 0 || pathname === '/register/confirm',
        ssr: true
    });

    const userProfileData = useGraphQLQuery(GET_USER_PROFILE, {
        errorPolicy: 'all',
        skip: parseInt(userId) <= 0 || pathname === '/register/confirm',
        ssr: true
    });

    useEffect(() => {
        if (parseInt(userId) > 0 && pathname !== '/register/confirm') {
            const userDetails =
                !userData.loading && userData.data && userData.data.user ? userData.data.user : {};
            const userProfile =
                !userProfileData.loading && userProfileData.data && userProfileData.data.userProfile
                    ? userProfileData.data.userProfile
                    : {};

            dispatch(
                updateUserDetails(
                    false,
                    userDetails?.usergroup_id ?? 1,
                    userDetails?.forum?.usergroups ?? [],
                    userProfile?.first_name ?? '',
                    userProfile?.last_name ?? '',
                    userDetails?.username ?? '',
                    userDetails?.forum?.avatar ?? '',
                    userDetails?.email ?? '',
                    userDetails?.dob ?? '',
                    userDetails?.gender ?? '',
                    userProfile?.current_qualifications ?? [],
                    userProfile?.future_qualifications ?? [],
                    userProfile?.year_group ?? 0,
                    userProfile?.career_phase ?? '',
                    userProfile?.current_subjects ?? [],
                    userProfile?.future_subjects ?? [],
                    userProfile?.current_learning_providers ?? [],
                    userProfile?.future_learning_providers ?? [],
                    userProfile?.mobile ?? '',
                    userProfile?.post_code ?? '',
                    userProfile?.country ?? '',
                    userProfile?.last_updated ?? '',
                    userProfile?.questions_answered ?? 0
                )
            );
        }
    }, [userId, userData.loading, userProfileData.loading]);

    return <>{children}</>;
}

import { Cookies } from 'react-cookie';
import { runtimeConfig } from '../runtimeConfig';

/**
 * Retrieves the user's fisher bucket from their cookies.
 * If the cookie does not exist then it calculates a fisher bucket and sets the cookie.
 * The fisher bucket is a random number between 1 and 8.
 * The cookie expires after 30 days.
 * @returns the user's fisher bucker number
 */
export default function getFisherBucket() {
    let cookies = new Cookies();

    const fisher = runtimeConfig.cookiePrefix + '_fisher';
    let fisherBucket = Number(cookies.get(fisher));

    // if cookie doesn't exist, set one
    if (!fisherBucket) {
        fisherBucket = Math.floor(Math.random() * 8) + 1;
        const expires = new Date();
        expires.setDate(expires.getDate() + 30);
        cookies.set(fisher, fisherBucket, { expires: expires });
    }

    return fisherBucket;
}

import { SyntheticEvent } from 'react';
import styled from '@emotion/styled';
import Link from '@mui/material/Link';
import MuiAccordion, { AccordionProps as MuiAccordionProps } from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import useTranslation from '../../../../hooks/useTranslation';
import { useAppTheme } from '@thestudentroom/lib.themes';
import { recordClick } from '@thestudentroom/datalayer';
import { runtimeConfig } from '../../../../runtimeConfig';
import { MdExpandMore } from 'react-icons/md';
import Typography from '@mui/material/Typography';

type AccordionProps = {
    level: number;
};

const Accordion = styled((props: AccordionProps & MuiAccordionProps) => (
    <MuiAccordion disableGutters square {...props} />
))(({ level }) => {
    const theme = useAppTheme();

    return {
        border: 'none',
        backgroundColor: `${
            level == 1
                ? theme.palette.primary.main
                : level == 2
                ? theme.palette.primary.dark
                : '#4a3a8d' // Only place this will be used
        }`,
        boxShadow: 'none',
        '&:before': {
            display: 'none'
        }
    };
});

type NavItemProps = {
    index: string;
    titleKey: string;
    url?: string;
    children?: Array<NavItemProps>;
    parentTitleKey?: string;
};

export default function BurgerMenuLink(props: NavItemProps) {
    const { t } = useTranslation();
    const theme = useAppTheme();
    const children = props.children && props.children.length ? props.children : null;
    if (!props.titleKey) return null;

    // Get level to style accordingly
    const level = props.index.split('-').length;

    const sendCollapseExpandTracking = (event: SyntheticEvent, expanded: boolean) => {
        // if the user has clicked the expand icon trigger the expand event
        const eventTarget = event.target as HTMLElement;
        if (typeof eventTarget.getAttribute('href') != 'string') {
            // get the subsection name including the parent
            const subsection = props.parentTitleKey
                ? `${props.parentTitleKey}_${props.titleKey}`
                : `${props.titleKey}`;

            recordClick({
                group: 'navigating',
                section: 'header',
                subsection: `main-navigation_${subsection}`,
                item: t(`navigation.${props.titleKey}`),
                action: expanded ? 'open' : 'close'
            });
        } else {
            // get the subsection name excluding the current item
            const subsection = props.parentTitleKey ? `_${props.parentTitleKey}` : ``;

            // Convert any relative TSR links to absolute paths
            const initialURL = props.url ?? '';
            const fullURL = /^https?:\/\//i.test(initialURL)
                ? initialURL
                : runtimeConfig.appDomain + initialURL;

            recordClick({
                group: 'navigating',
                section: 'header',
                subsection: `main-navigation${subsection}`,
                item: t(`navigation.${props.titleKey}`),
                action: 'select',
                url: fullURL
            });
        }
    };

    return (
        <Accordion
            key={`burger-menu-${props.index}`}
            level={level}
            onChange={(event: SyntheticEvent, expanded: boolean) => {
                sendCollapseExpandTracking(event, expanded);
            }}
        >
            <AccordionSummary
                expandIcon={
                    children ? (
                        <MdExpandMore
                            size={24}
                            color={theme.white}
                            style={{ marginRight: theme.spacing(3) }}
                        />
                    ) : null
                }
                aria-controls={children ? 'nav' + props.index + '-content' : undefined}
                id={'nav' + props.index + '-header'}
                sx={{
                    marginLeft: theme.spacing(3),
                    '&.Mui-expanded': {
                        marginRight: theme.spacing(3)
                    }
                }}
            >
                {/* TODO - Somehow disable accordion expanding when clicking on link*/}
                <Link href={props.url} underline="none" sx={{ color: 'primary.contrastText' }}>
                    <Typography variant="body1">{t(`navigation.${props.titleKey}`)}</Typography>
                </Link>
            </AccordionSummary>
            {children ? (
                <AccordionDetails sx={{ p: 0 }}>
                    {children.map((item, index) => {
                        // grab the full parent title key hierarchy for click tracking
                        const parentTitleKeys = [props.parentTitleKey, props.titleKey].filter(
                            Boolean
                        );
                        const parentTitleHierachy = parentTitleKeys.join('_');

                        return (
                            <BurgerMenuLink
                                {...item}
                                key={index}
                                index={props.index + '-' + index}
                                parentTitleKey={parentTitleHierachy}
                            />
                        );
                    })}
                </AccordionDetails>
            ) : null}
        </Accordion>
    );
}

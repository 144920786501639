import translations from '../i18n/locales/en/translations.json';

export function t(path: string, replacements: any = {}): string {
    let string = path.split('.').reduce((o: any, i: string) => o[i], translations);
    for (var rep in replacements) {
        string = string.replace('{{' + rep + '}}', replacements[rep]);
    }

    return string;
}

export default function useTranslation() {
    return { t };
}

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../client';
import { useLazyQuery } from '@apollo/client';
import { setupGoogleSignin, socialLoginQuery } from '@thestudentroom/package.accounts';
import { useLocation } from 'react-router-dom';
import { runtimeConfig } from '../../runtimeConfig';

export default function GoogleOneTap() {
    const [getTokens] = useLazyQuery(socialLoginQuery);
    const { pathname } = useLocation();
    const hasUserId = useSelector((state: RootState) => state.user.userId) > 0;

    // Dont show Google one tap on these routes
    const routesToExclude = ['/register', '/login', '/register/confirm', '/unsubscribe'];
    const hideOneTap = routesToExclude.includes(pathname);
    useEffect(() => {
        // On pages where we want to show One Tap
        if (!hideOneTap) {
            // If we're a guest, initialise One Tap
            if (!hasUserId) {
                setupGoogleSignin({
                    googleClientId: runtimeConfig.googleClientId,
                    promptOneTap: true,
                    context: 'signin',
                    getTokens: getTokens,
                    route: 'google-one-tap',
                    website: 'TSR'
                });
            } else {
                // If we're a member, hide One Tap
                if (typeof google !== 'undefined') {
                    google.accounts.id.cancel();
                }
            }
        }
    }, [hasUserId]);


    return null;
}

import { loadableReady } from '@loadable/component';
import { createStore } from 'redux';
import { hydrateRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { rootReducer } from './store';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { HelmetProvider } from 'react-helmet-async';
import { CookiesProvider } from 'react-cookie';

// GraphQL
import { ApolloProvider } from '@apollo/client';
import getApolloClient from './api/graphql/client';

// Error handling
import ErrorBoundary from './components/ErrorBoundary';
import ErrorPage from './pages/Error';

// EmotionJS styling
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

import GrowthbookProvider from 'providers/GrowthbookProvider';
import App from './App';
import { runtimeConfig } from './runtimeConfig';
import GoogleOneTap from './components/Social/GoogleOneTap';

// Set up webpack assets path
__webpack_public_path__ = runtimeConfig.assetsPublicPath;

// Create Redux store with state injected by the server
export const store = createStore(rootReducer, (window as any).__PRELOADED_STATE__);

export const apolloClient = getApolloClient(store);

const postParams = (window as any).__PRELOADED_PARAMS__;

export type RootState = ReturnType<typeof store.getState>;

// Allow the passed state to be garbage-collected
delete (window as any).__PRELOADED_STATE__;
delete (window as any).__APOLLO_STATE__;
delete (window as any).__PRELOADED_PARAMS__;

const cache = createCache({ key: 'custom', prepend: true });

loadableReady(() => {
    try {
        const container = document.getElementById('root') as HTMLElement;

        hydrateRoot(
            container,
            <ErrorBoundary errorComponent={<ErrorPage errorType={'error'} />}>
                <HelmetProvider>
                    <ApolloProvider client={apolloClient}>
                        <Provider store={store}>
                            <StyledEngineProvider injectFirst>
                                <BrowserRouter>
                                    <GoogleOneTap />
                                    <CacheProvider value={cache}>
                                        <CookiesProvider>
                                            <GrowthbookProvider>
                                                <App postParams={postParams} />
                                            </GrowthbookProvider>
                                        </CookiesProvider>
                                    </CacheProvider>
                                </BrowserRouter>
                            </StyledEngineProvider>
                        </Provider>
                    </ApolloProvider>
                </HelmetProvider>
            </ErrorBoundary>
        );
    } catch (err) {
        console.log(
            'There was an error hydrating the page - some functionality may not be available'
        );
    }
});

if (module.hot) {
    module.hot.accept();
}

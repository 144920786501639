import { useEffect, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

interface ScrollToTopProps {
    children: ReactNode;
}

export default function ScrollToTop(props: ScrollToTopProps) {
    const location = useLocation();

    useEffect(() => {
        // only scroll to the top of the page if the link is not to a specific post
        if (typeof window !== 'undefined' && (!location.search.includes('p=') && !location.search.includes('#post') && !location.search.includes('goto=newpost'))) {
            window.scrollTo(0, 0);
        }
    }, [location]);

    return <>{props.children}</>;
}

import { AnyAction } from 'redux';
import { SHOW_MODAL } from './actions';

interface ModalReducerState {
    showModal: boolean;
    modalType: string;
    params?: any;
    dismissible: boolean;
}

export const initialState: ModalReducerState = {
    showModal: false,
    modalType: '',
    params: {},
    dismissible: true
};

export default function modalReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case SHOW_MODAL:
            return {
                ...state,
                showModal: action.payload.show,
                modalType: action.payload.modalType,
                params: action.payload.params,
                dismissible: action.payload.dismissible
            };
        default:
            return state;
    }
}

import { gql } from 'graphql-tag';

export const WATCH_THREAD = gql`
    mutation WatchThread($threadId: Int!) {
        watchThread(threadId: $threadId) {
            watched
            error
        }
    }
`;

export const UNWATCH_THREAD = gql`
    mutation UnwatchThread($threadId: Int!) {
        unwatchThread(threadId: $threadId) {
            watched
            error
        }
    }
`;

export const GET_NOTIFICATION_COUNT = gql`
    query notificationCount {
        notificationCount
    }
`;

export const GET_WATCHED_THREAD_IDS = gql`
    query Watched_list {
        watched_thread_list {
            watched
            total_results
        }
    }
`;

export const GET_WATCHED_FORUM_IDS = gql`
    query Watched_forum_list {
        watched_forum_list {
            watched
            total_results
        }
    }
`;

export const GET_NOTIFICATION_LIST = gql`
    query Notification_list($page: Int, $per_page: Int, $filters: [KeyValue]) {
        notification_list(page: $page, per_page: $per_page, filters: $filters) {
            notifications {
                created_at
                dismissed
                from_user
                group
                id
                title
                url
            }
            total_results
        }
    }
`;


import { recordLeadGenInteraction } from '@thestudentroom/datalayer';
import { showModal } from '../../store/modals/actions';
import { updateSecurityToken } from '../../store/user/actions';
import getUserToken from '../../helpers/User/getUserToken';
import { LearningProvider } from 'types/learning_provider';
import { UserReducerState } from '../../store/user/reducer';
import { CREATE_LEAD } from '../../api/Leads/graph';

function createClickLead(learningProviderId: number, leadTypeCode: string, route: string) {

    let urlPath = '';
    if (typeof window !== 'undefined') {
        urlPath = window.location.pathname;
    }

    import('../../client').then(({apolloClient}) => {
        apolloClient.mutate({
            mutation: CREATE_LEAD,
            variables: {
                leadTypeCode: leadTypeCode,
                learningProviderId: learningProviderId,
                enquiry: '',
                route: route,
                urlPath: urlPath
            },
            errorPolicy: 'all'
        });
    });
}

export function getButtons(learning_provider: LearningProvider, buttonOrder: string[] = ['openday', 'prospectus', 'info', 'website']) {
    let tmpButtons = [];
    if (learning_provider?.lead_generation?.open_days) {
        tmpButtons.push({
            type: 'openday',
            text: 'Open days',
            outcome: learning_provider.lead_generation.open_days
        });
    }

    if (learning_provider?.lead_generation?.prospectus) {
        tmpButtons.push({
            type: 'prospectus',
            text: 'Get prospectus',
            outcome: learning_provider.lead_generation.prospectus
        });
    }

    if (learning_provider?.lead_generation?.request_info) {
        tmpButtons.push({
            type: 'info',
            text: 'Request info',
            outcome: learning_provider.lead_generation.request_info
        });
    }

    if (learning_provider?.lead_generation?.visit_website) {
        tmpButtons.push({
            type: 'website',
            text: 'Visit website',
            outcome: learning_provider.lead_generation.visit_website
        });
    }

    // Order the buttons as per the variation
    tmpButtons.sort(function (a: any, b: any) {
        return buttonOrder.indexOf(a.type) - buttonOrder.indexOf(b.type);
    });

    return tmpButtons;
}

function getButtonType(type: string) {
    switch(type) {
        case 'openday':
            return 'open_days-button';
        case 'prospectus':
            return 'prospectus-button';
        case 'info':
            return 'info-button';
        case 'website':
        default:
            return 'website-button';
    }
}

export function onLeadGenButtonClick(user: UserReducerState, learningProviderId: number, type: string, outcome: string, buttonOrder: number, route: string) {

    recordLeadGenInteraction(
        'click',
        route,
        'lead-gen-buttons',
        getButtonType(type),
        'standard',
        buttonOrder,
        outcome,
        learningProviderId,
        undefined, // iedLearningProviderId
        undefined, // tugLearningProviderId
    );

    if (outcome == 'form') {
        if (user.userId) {
            openLeadGenModal(user, learningProviderId, type, route);
        } else {
            openRegPrompt(learningProviderId, type, outcome, route);
        }
    } else {
        handleLeadGenClick(learningProviderId, type, outcome, buttonOrder, route);
    }
}

function handleLeadGenClick(learningProviderId: number, type: string, outcome: string, buttonOrder: number, route: string) {
    window.open(outcome, '_blank');

    let buttonType: "open_days-button" | "prospectus-button" | "info-button" | "website-button" | "info-form" | "prospectus-form" | "website-form" | "open_days-form" = getButtonType(type);
    let leadType = 'WEBSITE_CLICK';
    switch(type) {
        case 'openday':
            leadType = 'OPENDAY_REFERRAL';
            break;
        case 'prospectus':
            leadType = 'PROSPECTUS_REFERRAL';
            break;
        case 'info':
            leadType = 'ENQUIRY_REFERRAL';
            break;
        case 'website':
        default:
            leadType = 'WEBSITE_CLICK';
            break;
    }

    recordLeadGenInteraction(
        'generate_lead',
        route,
        'lead-gen-buttons',
        buttonType,
        'standard',
        buttonOrder,
        outcome,
        learningProviderId,
        undefined, // tugLearningProviderId
        undefined, // tsrLearningProviderId
    );

    createClickLead(learningProviderId, leadType, route);
}

function openLeadGenModal(user: UserReducerState, learningProviderId: number, type: string, route: string) {
    import('../../client').then(({store}) => {
        store.dispatch(showModal(true, 'lead_gen', {
            currentValues: {
                email: user.email,
                first_name: user.first_name,
                last_name: user.last_name,
                mobile: user.mobile,
                post_code: user.post_code,
                country: user.country,
                learning_providers: [learningProviderId],
                subjects: user.current_subjects,
                future_qualifications: user.future_qualifications.length ? user.future_qualifications : []
            },
            goBack: () => {
                store.dispatch(showModal(false, '', {}));
            },
            onQuestionComplete: (answer: any, skip: boolean) => {
                if (skip || !answer) {
                    // Show confirmation?
                } else {
                    store.dispatch(showModal(false, '', {}));
                }
            },
            tracking: {
                route: route,
                urlPath: location.pathname,
            },
            website: 'TSR',
            loggedIn: !!user.userId,
            isPostgraduate: false,
            leadGenType: type,
        }, true));
    });
}

async function getMemberState(): Promise<UserReducerState> {
    return await import('../../client').then(async ({store}) => {
        // Wait until we have the email address in the store
        while (!store.getState().user.email) {
            await new Promise(resolve => setTimeout(resolve, 100));
        }
        const user = store.getState().user;
        return user;
    });
}

function openRegPrompt(learningProviderId: number, type: string, outcome: string, route: string) {
    import('../../client').then(({store}) => {
        store.dispatch(showModal(true, 'lead_gen_reg', {
            route: route,
            afterRegister: () => {
                // Close the modal while being logged in
                store.dispatch(showModal(false, '', {}));
                // Putting this behind a timeout to ensure the UX is switched to the member experience fully
                setTimeout(async () => {
                    await getUserToken().then(async (usertoken: any) => {
                        if (usertoken !== 'guest' && usertoken !== '') {
                            store.dispatch(updateSecurityToken(usertoken as string));
                            const user = await getMemberState();
                            // Open lead gen form once successfully logged in
                            openLeadGenModal(user, learningProviderId, type, outcome);
                        }
                        return true;
                    });

                }, 500);
            },
            afterLogin: (userid: string) => {
                // Putting this behind a timeout to ensure the UX is switched to the member experience fully
                setTimeout(async () => {
                    await getUserToken().then(async (usertoken: any) => {
                        if (usertoken !== 'guest' && usertoken !== '') {
                            store.dispatch(updateSecurityToken(usertoken));

                            const user = await getMemberState();
                            openLeadGenModal(user, learningProviderId, type, outcome);
                        } else {
                            store.dispatch(showModal(false, '', {}));
                        }
                        return true;
                    });
                }, 500);
            }
        }, true));
    });
}

/**
 * Redirects the user to the app store based on their device.
 */
export default function redirectToAppStore() {
    const userAgent = navigator.userAgent;
    
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        window.location.href = 'https://apps.apple.com/gb/app/the-student-room/id6459021417';
    } else if (/android/i.test(userAgent)) {
        window.location.href = 'https://play.google.com/store/apps/details?id=uk.co.thestudentroom.app';
    }
}

export const SET_COOKIE = 'SET_COOKIE';

export function setCookie(cookie: string) {
    return {
        type: SET_COOKIE,
        payload: {
            cookie
        }
    };
}

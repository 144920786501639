import { combineReducers } from 'redux';
import adverts from './adverts/reducer';
import thread from './thread/reducer';
import reply from './reply/reducer';
import user from './user/reducer';
import cookies from './cookies/reducer';
import modals from './modals/reducer';
import post from './post/reducer';

export const rootReducer = combineReducers({
    adverts,
    thread,
    reply,
    user,
    cookies,
    modals,
    post
});

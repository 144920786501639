import styled from '@emotion/styled';
import { DiscussionBubble } from '@thestudentroom/lib.themes';


export const StyledErrorPage = styled.div`
    width: 100vw;
    height: 75vh;
    position: relative;
    display: flex;
    justify-content: center;

    @media (min-width: 671px) {
        height: 100vh;
    }
`;

export const StyledErrorMessage = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing(5)};
    position: absolute;
    padding-top: ${(props) => props.theme.spacing(8)};
    margin: ${(props) => props.theme.spacing(0, 4)};

    @media (min-width: 671px) {
        padding-top: ${(props) => props.theme.spacing(68)};
        margin: 0;
        width: 50%;
    }
`;

export const ErrorMessageTitle = styled.h2`
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
    margin: 0;
`;

export const ErrorMessageContent = styled.p`
    font-size: 20px;
    line-height: 24px;
    margin: 0;
`;

export const TopLeftBubble = styled(DiscussionBubble)`
    display: none;
    position: absolute;
    top: 0;
    left: 0;

    @media (min-width: 981px) {
        display: block;
    }

`;

export const BottomLeftBubble = styled(DiscussionBubble)`
    position: absolute;
    bottom: 0;
    left: 0;

    @media (min-width: 671px) {
        height: 300px !important;
    }

    @media (min-width: 981px) {
        width: 350px !important;
    }
`;

export const TopRightBubble = styled(DiscussionBubble)`
    display: none;
    position: absolute;
    top: 0;
    right: 10px;

    @media (min-width: 671px) {
        display: block;
    }
`;

export const RightBubble = styled(DiscussionBubble)`
    display: none;
    position: absolute;
    top: 35%;
    right: 0;

    @media (min-width: 671px) {
        display: block;
    }
`;

export const BottomRightBubble = styled(DiscussionBubble)`
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;

    @media (min-width: 981px) {
        bottom: 50px;
        right: 60px;
        width: 450px !important;
        height: 320px !important;
        border-bottom-right-radius: 72px;
        border-top-right-radius: 72px;
    }
`;

export const BottomRightCornerBubble = styled(DiscussionBubble)`
    position: absolute;
    bottom: 30px;
    right: 0;
    z-index: 1;

    @media (min-width: 981px) {
        bottom: 0;
        height: 100px !important;
        width: 320px !important;
        border-bottom-left-radius: 0 !important;
    }
`;

import getSecurityToken from '../../helpers/getSecurityToken';

export default function () {
    return new Promise(async (resolve, reject) => {
        let attempts = 0;

        const fetchData = async () => {
            try {
                let result = await getSecurityToken();
                if ((!result || result === 'guest') && attempts < 10) {
                    attempts++;
                    fetchData(); // Call the function recursively
                } else {
                    resolve(result); // Resolve the promise with the result
                }
            } catch (error) {
                reject(''); // Reject the promise with the error
            }
        };

        fetchData();
    });
};

export const UPDATE_POST_DETAILS = 'UPDATE_POST_DETAILS';

export function updatePostDetails(postHash: string, postStartTime: number) {
    return {
        type: UPDATE_POST_DETAILS,
        payload: {
            postHash,
            postStartTime
        }
    };
}

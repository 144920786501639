import { useState } from 'react';
import TSRAdSlot from '../TSRAdSlot';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

function isPostMessageFromAdvertIframe(e: any) {
    if (
        !(
            e.origin === 'http://www.googletagservices.com' ||
            e.origin === 'https://www.googletagservices.com' ||
            e.origin.match(/(^|^[^:]+:\/\/|[^\.]+\.)safeframe\.googlesyndication\.com/g) ||
            e.origin === 'http://tpc.googlesyndication.com' ||
            e.origin === 'https://tpc.googlesyndication.com' ||
            e.origin === location.origin
        ) ||
        typeof e.data !== 'object' ||
        typeof e.data.unit !== 'string' ||
        !e.data.unit.match(/^tsr-[\w\d-]+$/)
    ) {
        return false;
    }

    return true;
}

export default function TakeoverAdvert() {
    const [slotRenderEndedEventAdded, setSlotRenderEndedEventAdded] = useState(false);
    const [messageEventAdded, setMessageEventAdded] = useState(false);
    const hasSpaceForWallpapers = useMediaQuery('(min-width:1650px)');
    // Header ad behaviour test
    const ownershipHeaderTest = useFeatureIsOn('tug-1229-ad-header-test');

    if (!hasSpaceForWallpapers) {
        return null;
    }

    const slotId = 'page-takeover';
    const adUnit = 'tsr-page-takeover-all-site';

    if (typeof window !== 'undefined') {
        window.googletag = window.googletag || { cmd: [] };

        if (!slotRenderEndedEventAdded) {
            // Remove adverts if there is still one from a previous page and this page doesn't have one
            googletag.cmd.push(function () {
                googletag.pubads().addEventListener('slotRenderEnded', function (event) {
                    if (event.slot.getSlotElementId() == slotId) {
                        if (event.isEmpty) {
                            const leftPanelContainer =
                                document.getElementById('wallpaper-panel-left');
                            if (leftPanelContainer) {
                                leftPanelContainer.children[0]?.remove();
                            }

                            const rightPanelContainer =
                                document.getElementById('wallpaper-panel-right');
                            if (rightPanelContainer) {
                                rightPanelContainer.children[0]?.remove();
                            }
                        }
                    }
                });
            });
            setSlotRenderEndedEventAdded(true);
        }

        if (!messageEventAdded) {
            // Listen out for postMessages coming from advert iframes
            window.addEventListener('message', function (e: any) {
                if (isPostMessageFromAdvertIframe(e) && e.data.unit === adUnit) {
                    var parser = new DOMParser();
                    var htmlDoc = parser.parseFromString(e.data.payload, 'text/html');

                    const leftPanel = htmlDoc.getElementsByClassName('panel-left')[0];
                    const leftPanelContainer = document.getElementById('wallpaper-panel-left');
                    if (leftPanelContainer) {
                        leftPanelContainer.innerHTML = leftPanel.innerHTML;
                    }

                    const rightPanel = htmlDoc.getElementsByClassName('panel-right')[0];
                    const rightPanelContainer = document.getElementById('wallpaper-panel-right');
                    if (rightPanelContainer) {
                        rightPanelContainer.innerHTML = rightPanel.innerHTML;
                    }
                }
            });
            setMessageEventAdded(true);
        }
    }

    return (
        <TSRAdSlot
            slotId={slotId}
            sizes={[[1, 1]]}
            adUnit={adUnit}
            targetingArguments={{ variation: ownershipHeaderTest ? '1' : 'default' }}
            lazy={false}
        />
    );
}

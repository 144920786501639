import { Fragment } from 'react';
import { recordClick } from '@thestudentroom/datalayer';
import useTranslation from '../../hooks/useTranslation';
import { RootState } from '../../client';
import theme from '../../Layouts/Main/theme';
import styled from '@emotion/styled';
import { useSelector, useDispatch } from 'react-redux';

// Custom styles
import { PrimaryPillButton } from '@thestudentroom/lib.themes';
import { MdAdd } from 'react-icons/md';
import { showModal } from '../../store/modals/actions';

const LongText = styled.span`
    display: none;

    @media (min-width: 860px) {
        display: block;
    }
`;

const ShortText = styled.span`
    display: block;

    @media (min-width: 860px) {
        display: none;
    }
`;

export default function AskAQuestion() {
    const { t } = useTranslation();
    const userId: number = parseInt(useSelector((state: RootState) => state.user.userId));
    const reduxDispatch = useDispatch();
    
    const openRegModal = () => {
        setTimeout(() => {
            reduxDispatch(showModal(true, 'guest_askaquestion', {}, true));
        }, 0);
    };

    const handleClick = () => {
        setTimeout(() => {
            recordClick({
                group: 'thread-creation',
                section: 'header',
                subsection: 'aaq',
                item: t('buttons.askaquestion'),
                action: 'open'
            });
    
            if (userId === 0) {
                openRegModal();
            }
            else {
                reduxDispatch(showModal(true, 'ask_a_question', {}, true));
            }    
        }, 0);
    };

    return (
        <Fragment key="aaq-container">
            <PrimaryPillButton
                startIcon={<MdAdd size={20} fill={theme.text.primary} />}
                aria-label={t('buttons.askaquestion')}
                onClick={handleClick}
            >
                <LongText>{t('buttons.askaquestion')}</LongText>
                <ShortText>{t('buttons.ask')}</ShortText>
            </PrimaryPillButton>
        </Fragment>
    );
}

import {
    MdAnnouncement,
    MdOutlineNotificationsActive,
    MdQuestionMark,
    MdShield,
    MdError,
    MdAlternateEmail,
    MdAssignmentLate,
    MdComment,
    MdDelete,
    MdDone,
    MdFlag,
    MdOutlineStar,
    MdPersonAdd,
    MdThumbUp,
    MdOutlinePriorityHigh,
    MdGroupAdd,
    MdOutlineComment,
    MdAddAPhoto
} from 'react-icons/md';

export const getNotificationIcon = (category: string) => {
    switch (category) {
        case 'dpc_continue':
            return <MdAnnouncement size={'25'} data-testid={category} />;
        case 'unanswered':
            return <MdQuestionMark size={'25'} data-testid={category} />;
        case 'badge':
            return <MdShield size={'25'} data-testid={category} />;
        case 'error':
            return <MdError size={'25'} data-testid={category} />;
        case 'quote':
            return <MdComment size={'25'} data-testid={category} />;
        case 'mention':
            return <MdAlternateEmail size={'25'} data-testid={category} />;
        case 'threadwatched':
            return <MdOutlineStar size={'25'} data-testid={category} />;
        case 'repreceived':
            return <MdThumbUp size={'25'} data-testid={category} />;
        case 'threadapproved':
        case 'postapproved':
            return <MdDone size={'25'} data-testid={category} />;
        case 'threadreview':
        case 'postreview':
            return <MdAssignmentLate size={'25'} data-testid={category} />;
        case 'postdeleted':
        case 'threaddeleted':
            return <MdDelete size={'25'} data-testid={category} />;
        case 'follow':
        case 'friend':
            return <MdPersonAdd size={'25'} data-testid={category} />;
        case 'pprm':
            // updated post report
            return <MdFlag size={'25'} data-testid={category} />;
        case 'vm':
            return <MdOutlineComment size={'25'} data-testid={category} />;
        case 'pc':
            return <MdAddAPhoto size={'25'} data-testid={category} />;
        case 'socgroupinv':
        case 'socgroupreq':
            return <MdGroupAdd size={'25'} data-testid={category} />;
        case 'nonotifications':
            return <MdOutlinePriorityHigh size={'25'} data-testid={category} />;
        default:
            return <MdOutlineNotificationsActive size={'25'} data-testid={category} />;
    }
};

import { datadogRum } from '@datadog/browser-rum';
import { runtimeConfig } from '../runtimeConfig';

export function initDatadog(userType: string) {
    let appDomainType: string;
    if (runtimeConfig.appDomain.includes('staging')) {
        appDomainType = 'staging';
    } else if (runtimeConfig.appDomain.includes('tsr.dev')) {
        appDomainType = 'dev';
    } else {
        appDomainType = 'prod';
    }

    const datadogSessionPercentage: number = parseInt(
        runtimeConfig.datadogSessionPercentage ?? '0'
    );
    const datadogSessionReplayPercentage: number = parseInt(
        runtimeConfig.datadogSessionReplayPercentage ?? '0'
    );
    const datadogSessionMemberPercentage: number = parseInt(
        runtimeConfig.datadogSessionMemberPercentage ?? '0'
    );
    const datadogClientToken: string = runtimeConfig.datadogClientToken ?? '';
    const datadogApplicationId: string = runtimeConfig.datadogApplicationId ?? '';
    const datadogEnvironment: string = `${appDomainType}-${userType}`;
    const applicationVersion: string = runtimeConfig.version ?? '';

    if (!datadogApplicationId || !datadogClientToken) return;

    datadogRum.init({
        applicationId: datadogApplicationId,
        clientToken: datadogClientToken,
        site: 'datadoghq.com',
        env: datadogEnvironment,
        service: 'react',
        version: applicationVersion,
        sessionSampleRate:
            userType == 'member' ? datadogSessionMemberPercentage : datadogSessionPercentage,
        sessionReplaySampleRate: datadogSessionReplayPercentage,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        enableExperimentalFeatures: ['clickmap']
    });

    datadogRum.startSessionReplayRecording();
}

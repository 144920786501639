import { MouseEventHandler } from 'react';
import Avatar from '@mui/material/Avatar';
import { runtimeConfig } from '../../../runtimeConfig';
import { Skeleton } from '@mui/material';

interface AvatarProps {
    imageUrl: string;
    username: string;
    onClick?: MouseEventHandler<HTMLImageElement>;
    sx?: Object;
    isFirstPost?: boolean;
}

export default function UserAvatar(props: AvatarProps) {
    const staticDomain = runtimeConfig.staticDomain;
    const baseUrl = staticDomain + '/forum/';
    const hasStaticDomain =
        props.imageUrl.includes(staticDomain) && props.imageUrl.includes('/forum');
    const imgUrl = hasStaticDomain ? props.imageUrl : baseUrl + props.imageUrl;

    return (
        <>
            {props.imageUrl ? (
                <Avatar
                    alt={`Avatar for ${props.username}`}
                    src={imgUrl}
                    onClick={props.onClick}
                    sx={props.sx}
                    imgProps={props.isFirstPost ? { loading: 'eager' } : { loading: 'lazy' }}
                />
            ) : (
                <Skeleton variant="circular" sx={props.sx} />
            )}
        </>
    );
}

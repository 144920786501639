import { targetingParamType } from './reducer';
export const SET_PAGE_TARGETING_PARAMS = 'SET_PAGE_TARGETING_PARAMS';
export const UPDATE_BOTTOM_SHEET_ACTIVE = 'UPDATE_BOTTOM_SHEET_ACTIVE';
export const SET_OWNERSHIP = 'SET_OWNERSHIP';

export function setPageTargetingParams(targetingParams: targetingParamType) {
    return {
        type: SET_PAGE_TARGETING_PARAMS,
        targetingParams
    };
}

export function updateBottomSheetActive(active: null | boolean) {
    return {
        type: UPDATE_BOTTOM_SHEET_ACTIVE,
        active
    };
}

export function setOwnership(learning_provider?: any) {
    return {
        type: SET_OWNERSHIP,
        learning_provider
    }
}

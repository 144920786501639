export const SHOW_MODAL = 'SHOW_MODAL';

export function showModal(show: boolean, modalType: string, params?: any, dismissible?: boolean) {
    return {
        type: SHOW_MODAL,
        payload: {
            show,
            modalType,
            params,
            dismissible
        }
    };
}

import loadable from '@loadable/component';
import ReportModal from '../../components/Post/ReportButton/ReportModal';

// Loadable Compomnents
const NewRegistrationModal = loadable(
    () => import('../../components/Registration/NewRegistrationModal')
);
const NewDPCModal = loadable(() => import('../../components/DPC/NewDPCModal'));
const NewConversationModal = loadable(
    () => import('../../components/Messages/NewConversationModal')
);
const AskAQuestionModal = loadable(
    () => import('../../components/AskAQuestion/Modal')
);
const AppPromoModal = loadable(() => import('../../components/Registration/AppPromoModal'));
const ExistingMemberModal = loadable(() => import ('../../components/DPC/ExistingMember/ExistingMemberModal'));
const LeadGenModal = loadable(() => import('../../components/LeadGeneration/modal'));

export default function getModalToDisplay(modalType: string, params?: any) {
    let modalToRender = null;
    switch (modalType) {
        // Modals with actions after reg/login
        case 'watchthread':
        case 'report_post':
        case 'lead_gen_reg':
            modalToRender = (
                <NewRegistrationModal
                    regRoute={params.route}
                    afterLogin={params.afterLogin ?? undefined}
                    afterRegister={params.afterRegister ?? undefined}
                />
            );
            break;
        // Non-dismissible reg prompts
        case 'returnvisitregprompt':
        case 'threadregprompt':
            modalToRender = (
                <NewRegistrationModal
                    regRoute={modalType}
                    showAppButton={params.showAppButton}
                    initialType={params.initialType}
                    appPromoViewed={params.appPromoViewed}
                    hideClose
                />
            );
            break;
        // Dismissible reg prompts
        case 'headersignup':
        case 'home_signup':
        case 'homefeedsignup':
        case 'poll':
        case 'guest_askaquestion':
            modalToRender = <NewRegistrationModal regRoute={modalType} />;
            break;
        // Data capture
        case 'dpc_register':
        case 'personalise':
        case 'dpc_prompt':
        case 'existing_member_data_capture':
        case 'homefeeddpc':
        case 'dpc_continue':
            modalToRender = <NewDPCModal route={params.route ?? modalType} />;
            break;
        case 'newconversation':
            modalToRender = <NewConversationModal />;
            break;
        case 'existing_member':
            modalToRender = <ExistingMemberModal />;
            break;
        // Other modals
        case 'report_post_modal':
            modalToRender = (
                <ReportModal
                    open={params.open}
                    reportUrl={params.reportUrl}
                    onClose={params.onClose}
                />)
            break;
        case 'app_promo':
            modalToRender = <AppPromoModal handleSignUpAnotherWay={params.handleSignUpAnotherWay} route={params.route} />;
            break;
        case 'ask_a_question':
            modalToRender = <AskAQuestionModal />;
            break;
        case 'lead_gen':
            modalToRender = <LeadGenModal {...params} />;
            break;
        default:
            break;
    }

    return modalToRender;
}

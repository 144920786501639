export default function (dob: string): number | null {
    let yearGroup = null;

    if (dob !== '0000-00-00') {
        const currentDate = new Date();
        let currentYear = currentDate.getFullYear();
        const academicYear = new Date(Date.parse(currentYear + '-09-01'));
        // If we're currently after september 1st, we need to change the academic year to be next year
        if (currentDate >= academicYear) {
            currentYear++;
        }

        const dobDate = new Date(Date.parse(dob));
        const compareDate = new Date(Date.parse(currentYear + '-08-31'));
        yearGroup = compareDate.getFullYear() - dobDate.getFullYear() - 1;
    }

    return yearGroup;
}

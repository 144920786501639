import { useState } from 'react';
import TSRAdSlot from '../TSRAdSlot';
import { useDispatch } from 'react-redux';
import { updateBottomSheetActive } from '../../../store/adverts/actions';
import { BottomSheet } from './styles';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

export default function BottomSheetAdvert() {
    const slotId = 'bottom-sheet';
    const adUnit = 'tsr-alert-unit-mobile';

    // Header ad behaviour test
    const ownershipHeaderTest = useFeatureIsOn('tug-1229-ad-header-test');

    return (
        <TSRAdSlot
            slotId={slotId}
            sizes={[['fluid'], [320, 80]]}
            sizeMapping={[
                [[670, 0],  [['fluid']]],
                [[0, 0], [[320, 80], ['fluid']]],
            ]}
            adUnit={adUnit}
            targetingArguments={{ variation: ownershipHeaderTest ? '1' : 'default' }}
            lazy={false}
        />
    );
}

export function BottomSheetDiv(props: React.HTMLProps<HTMLDivElement>) {
    const dispatch = useDispatch();
    const [className, setclassName] = useState('hidden');

    if (typeof window !== 'undefined') {
        window.googletag = window.googletag || { cmd: [] };

        // Remove advert if there is still one from a previous page and this page doesn't have one
        googletag.cmd.push(function () {
            googletag.pubads().addEventListener('slotRenderEnded', function (event) {
                if (event.slot.getSlotElementId() == props.id) {
                    const bottomSheetElement = document.getElementById('bottom-sheet');
                    if (bottomSheetElement) {
                        if (!event.isEmpty) {
                            setclassName('visible');
                            dispatch(updateBottomSheetActive(true));
                        } else {
                            bottomSheetElement.children[0]?.remove();
                            setclassName('hidden');
                            dispatch(updateBottomSheetActive(false));
                        }
                    }
                }
            });
        });
    }

    return <BottomSheet id={props.id} className={className}></BottomSheet>;
}

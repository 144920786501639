import { FC, Fragment } from 'react';
import { useAppTheme } from '@thestudentroom/lib.themes';
import useTranslation from '../../hooks/useTranslation';
import { PrimaryButton } from '@thestudentroom/lib.themes';
import Link from '../../components/Link';
import {
    StyledErrorPage,
    StyledErrorMessage,
    ErrorMessageTitle,
    ErrorMessageContent,
    TopLeftBubble,
    BottomLeftBubble,
    TopRightBubble,
    RightBubble,
    BottomRightBubble,
    BottomRightCornerBubble
} from './styles';
import { MdChevronRight } from 'react-icons/md';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

interface ErrorPageProps {
    style?: Object;
    errorType: 'error' | 'notfound' | 'unauthorized';
}

const ErrorPage: FC<ErrorPageProps> = ({ style = {}, errorType }) => {
    const { t } = useTranslation();
    const theme = useAppTheme();

    let errorContent;

    switch (errorType) {
        case 'notfound':
            errorContent = (
                <Fragment key={'error-message-content'}>
                    <ErrorMessageTitle>{t('errors.fullpage.not-found-title')}</ErrorMessageTitle>
                    <ErrorMessageContent>
                        {t('errors.fullpage.not-found-message')}
                    </ErrorMessageContent>
                </Fragment>
            );
            break;
        case 'unauthorized':
            errorContent = (
                <Fragment key={'error-message-content'}>
                    <ErrorMessageTitle>{t('errors.fullpage.unauthorized-title')}</ErrorMessageTitle>
                    <ErrorMessageContent>
                        {t('errors.fullpage.unauthorized-message')}
                    </ErrorMessageContent>
                </Fragment>
            );
            break;
        case 'error':
        default:
            errorContent = (
                <Fragment key={'error-message-content'}>
                    <ErrorMessageTitle>{t('errors.fullpage.default-title')}</ErrorMessageTitle>
                    <ErrorMessageContent>
                        {t('errors.fullpage.default-message')}
                    </ErrorMessageContent>
                </Fragment>
            );
            break;
    }

    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <StyledErrorPage style={style}>
                <TopLeftBubble
                    height={240}
                    width={220}
                    bgColor={theme.palette.primary.light}
                    topLeft={true}
                    bottomLeft={true}
                    bottomRight={true}
                    radius={12.5}
                />

                <BottomLeftBubble
                    height={200}
                    width={100}
                    bgColor={`${theme.palette.info.light}75`}
                    topLeft={true}
                    bottomLeft={true}
                    bottomRight={true}
                    radius={12.5}
                />

                <TopRightBubble
                    height={200}
                    width={450}
                    borderColor={`${theme.palette.primary.main}50`}
                    topLeft={true}
                    bottomLeft={true}
                    topRight={true}
                    radius={18}
                />

                <RightBubble
                    height={250}
                    width={280}
                    bgColor={`${theme.palette.success.main}50`}
                    bottomRight={true}
                    radius={18}
                />

                <BottomRightBubble
                    height={160}
                    width={250}
                    borderColor={`${theme.palette.success.main}50`}
                    bottomLeft={true}
                    bottomRight={true}
                    topRight={true}
                    radius={18}
                />

                <BottomRightCornerBubble
                    height={200}
                    width={30}
                    bgColor={`${theme.palette.tertiary.main}50`}
                    bottomRight={true}
                    topRight={true}
                    radius={7}
                />

                <StyledErrorMessage>
                    {errorContent}
                    <Link url="/">
                        <PrimaryButton
                            endIcon={<MdChevronRight size={20} />}
                            style={{ padding: '0px 51px', height: '49px' }}
                        >
                            {t('errors.fullpage.take-me-home')}
                        </PrimaryButton>
                    </Link>
                </StyledErrorMessage>
            </StyledErrorPage>
        </MuiThemeProvider>
    );
};

export default ErrorPage;

import express from 'express';
import { ReactChild } from 'react';
import { GrowthBook, GrowthBookProvider as BaseProvider } from '@growthbook/growthbook-react';
import { recordExperimentExposure } from '@thestudentroom/datalayer';
import { runtimeConfig } from 'runtimeConfig';
import { useCookies } from 'react-cookie';

type ProviderProps = {
    children?: ReactChild;
    req?: express.Request;
};

export default function GrowthbookProvider({ children, req }: ProviderProps) {
    let gb;

    if (!req) {
        // Client side - set up growthbook
        gb = new GrowthBook({
            apiHost: runtimeConfig.growthBookHost,
            clientKey: runtimeConfig.growthBookKey,
            // Enable easier debugging during development
            enableDevMode: process.env.NODE_ENV === 'development' ? true : false,
            trackingCallback: (experiment, result) => {
                recordExperimentExposure(experiment.key, result.key, result.featureId ?? '');
            },
            subscribeToChanges: false,
            backgroundSync: false
        });

        // Set the features
        gb.setFeatures(window.env.growthbookFeatures);

        // Set the client/user id attributes
        const [cookies] = useCookies([runtimeConfig.cookiePrefix + '_userid', '_tsr']);

        const userId =
            typeof window !== 'undefined'
                ? parseInt(window.env.userId)
                : parseInt(cookies[runtimeConfig.cookiePrefix + '_userid'] ?? 0);

        const clientId = cookies['_tsr'];

        gb.setAttributes({
            userId: userId,
            clientId: clientId
        });
    } else {
        // Server side - grab it from the request
        gb = req.growthbook;
    }

    return <BaseProvider growthbook={gb}>{children}</BaseProvider>;
}

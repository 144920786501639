import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';

export const SearchBarDropdownContainer = styled.div`
    @media (min-width: 761px) {
        display: none;
    }
`;

export const Dropdown = styled(Popper)`
    width: 100%;
    z-index: 501;
`;

export const SearchBarContainer = styled.div`
    padding: 20px 10px;
    background-color: ${(props) => props.theme.palette.primary.dark};
`;

export const SearchIconButton = styled(IconButton)<{ open: boolean }>`
    background-color: ${(props) => props.open && props.theme.palette.primary.dark};
    padding: ${(props) => props.theme.spacing(1)};
    border-radius: 0px;
    height: ${(props) => props.open && '44px'};

    &:hover {
        background-color: ${(props) => props.open && props.theme.palette.primary.dark};
    }

    &:disabled {
        opacity: 0.5;
    }
`;

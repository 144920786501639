import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import useTranslation from '../../../../hooks/useTranslation';
import { recordClick } from '@thestudentroom/datalayer';
import { runtimeConfig } from '../../../../runtimeConfig';
import { useAppTheme } from '@thestudentroom/lib.themes';

const List = styled.ul`
    padding: 0;
`;

const ListItem = styled.li`
    list-style: none;
    padding: ${(props) => props.theme.spacing(0)};
    margin: ${(props) => props.theme.spacing(2, 0)};
    ${(props) => props.theme.typography.body2};
`;

type ColumnLinkProps = {
    index: string;
    titleKey: string;
    url?: string;
    children?: Array<ColumnLinkProps>;
};

function Column(item: ColumnLinkProps, topParentKey: string) {
    const { t } = useTranslation();
    const theme = useAppTheme();

    const sendTrackingEvent = (midParentKey: string, link: ColumnLinkProps) => {
        // Convert any relative TSR links to absolute paths
        const initialURL = link.url ?? '';
        const fullURL = /^https?:\/\//i.test(initialURL)
            ? initialURL
            : runtimeConfig.appDomain + initialURL;

        recordClick({
            group: 'navigating',
            section: 'header',
            subsection: `main-navigation_${topParentKey}_${midParentKey}`,
            item: t(`navigation.${link.titleKey}`) ?? '',
            action: 'click',
            url: fullURL
        });
    };

    return (
        <Grid key={`hover-menu-${item.index}`} sx={{ width: 1 / 4 }}>
            {/* If we have an item index, but no key provided - display an empty column, else show the translated column title key */}
            {item.index && !item.titleKey ? (
                <></>
            ) : (
                <Typography variant="subtitle2" component="p" color={theme.white}>
                    {t(`navigation.${item.titleKey}`)}
                </Typography>
            )}

            <List>
                {item.children
                    ? item.children.map((link, index) => {
                          return (
                              <ListItem key={`hover-menu-list-item-${index}`}>
                                  <Link
                                      color={theme.white}
                                      underline={'hover'}
                                      href={link.url}
                                      onClick={() => sendTrackingEvent(item.titleKey, link)}
                                  >
                                      {t(`navigation.${link.titleKey}`)}
                                  </Link>
                              </ListItem>
                          );
                      })
                    : null}
            </List>
        </Grid>
    );
}

type HoverDropdownMenuLinkProps = {
    items: Array<ColumnLinkProps>;
    topParentKey: string;
};

export default function HoverDropdownMenuLink(props: HoverDropdownMenuLinkProps) {
    return (
        <Grid container justifyContent="flex-start">
            {props.items.map((item) => Column(item, props.topParentKey))}
        </Grid>
    );
}

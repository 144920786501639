const padDatetime = (startNumber: number) => String(startNumber).padStart(2, '0');

export const createdAtTimestamp = (dateTimestamp: string, ssrSafe: boolean = false) => {
    const date = new Date(dateTimestamp);

    if (!ssrSafe) {
        // for ssrSafe, return based on UTC time to account for full page caching
        // otherwise, calculate based on the user's current timezone
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    }

    const day = padDatetime(date.getDate());
    const month = padDatetime(date.getMonth() + 1);
    const year = date.getFullYear();
    const hours = padDatetime(date.getHours());
    const minutes = padDatetime(date.getMinutes());

    // expected format: DD-MM-YYYY hh:mm
    return `${day}-${month}-${year} ${hours}:${minutes}`;
};

export const timestampCalculation = (dateTimestamp: string, ssrSafe: boolean = false) => {
    if (!dateTimestamp || dateTimestamp == null || dateTimestamp == '') {
        return '';
    }
    
    const intervals = [
        { label: 'year', seconds: 31536000 },
        { label: 'month', seconds: 2592000 },
        { label: 'week', seconds: 604800 },
        { label: 'day', seconds: 86400 },
        { label: 'hour', seconds: 3600 },
        { label: 'minute', seconds: 60 }
    ];

    const postDate = new Date(dateTimestamp.replace('UTC', ' ').replace(/\s/, 'T'));
    const timezoneOffset = postDate.getTimezoneOffset(); // Use the timezone offset from the post to ensure that server/client render the same HTML
    const postDateWithDst = new Date(postDate.setHours(postDate.getHours() - timezoneOffset / 60));
    const secondsAgo = Math.floor((new Date().getTime() - postDateWithDst.getTime()) / 1000);

    if (ssrSafe && secondsAgo < 86400) {
        // for ssrSafe, return hh:mm in UTC to account for full page caching
        return `${padDatetime(postDate.getUTCHours())}:${padDatetime(postDate.getUTCMinutes())}`;
    }

    if (secondsAgo <= 60) {
        // minimum timestamp is 1 minute ago, so return if seconds are 60 or fewer
        return '1 minute ago';
    }

    // default to minutes if an interval is not found
    const interval = intervals.find((i) => i.seconds < secondsAgo) || {
        label: 'minute',
        seconds: 60
    };
    const count = Math.floor(secondsAgo / interval.seconds);

    return `${count} ${interval.label}${count !== 1 ? 's' : ''} ago`;
};

import { Fragment, useState, useEffect } from 'react';
import { PrimaryOutlinedButton, useAppTheme } from '@thestudentroom/lib.themes';
import { recordClick } from '@thestudentroom/datalayer';
import { runtimeConfig } from '../../../../../runtimeConfig';
import MenuTemplate, { MenuItemTemplate } from '../Template';
import {
    SeeAll,
    MenuTitle,
    MenuBadge,
    PMFooterContainer,
    MarkAsRead
} from '../Template/styles';
import useTranslation from '../../../../../hooks/useTranslation';
import { MdOutlineMail, MdError, MdOutlineNotificationsActive } from 'react-icons/md';
import UserAvatar from '../../../../../components/User/Avatar/index';
// import { useQuery as useGraphQLQuery } from '@apollo/client';
// import { GET_CONVERSATIONS, GET_UNREAD_CONVERSATIONS } from '../../../../../api/Messages/graph';
// import { useIdleTimer } from 'react-idle-timer';
// import Conversation from '../../../../../components/Messages/ConversationList/Conversation';

interface PrivateMessagesToolbarProps {
    open: string | false;
    setOpen: Function;
}

export default function PrivateMessagesToolbar(props: PrivateMessagesToolbarProps) {
    const { t } = useTranslation();
    const theme = useAppTheme();
    const [errors, setErrors] = useState<string[]>([]);
    const [privateMessagesList, setPrivateMessagesList] = useState<string[]>([]);
    const [unreadCount, setUnreadCount] = useState(0);
    // const pollInterval = 30000;

    const getPrivateMessagesList = async () => {
        await fetch(
            `${runtimeConfig.appDomain}/notifications/getLatestNotifications?limit=5&offset=0&notifications=0&pm=1`
        )
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.pms.success) {
                    setPrivateMessagesList(data.pms.inbox.items);
                    setUnreadCount(data.pms.inbox.unread);
                    setErrors([]);
                } else {
                    setErrors(data.errors ?? [t('errors.notifications-menu')]);
                }
            })
            .catch((error) => {
                setErrors([error]);
            });
    };

    useEffect(() => {
        // call the function immediately to get initial PMs
        getPrivateMessagesList();

        // retrieve PMs again every 60 seconds to keep them updated
        const intervalId = setInterval(async () => {
            getPrivateMessagesList();
        }, 60000);

        return () => clearInterval(intervalId);
    }, []);

    // TODO: Conversations
    // const conversationData = useGraphQLQuery(GET_CONVERSATIONS, {
    //     variables: { page: 1, perPage: 5, filters: [] },
    //     errorPolicy: 'all',
    //     ssr: true,
    //     pollInterval: pollInterval
    // });

    // const unreadCountData = useGraphQLQuery(GET_UNREAD_CONVERSATIONS, {
    //     errorPolicy: 'all',
    //     ssr: true,
    //     pollInterval: pollInterval
    // });

    // useEffect(() => {
    //     if (unreadCountData.data) {
    //         setUnreadCount(unreadCountData.data.unread_conversation_count);
    //     }
    // }, [unreadCountData.loading]);

    // useEffect(() => {
    //     if (conversationData.error) {
    //         setErrors([t('errors.notifications-menu')]);
    //     }
    // }, [conversationData.loading, conversationData.error]);

    // stop polling if the user has been idle for 5 minutes,
    // start polling when they become active again
    // const onIdle = () => {
    //     conversationData.stopPolling();
    //     unreadCountData.stopPolling();
    // };
    // const onActive = () => {
    //     conversationData.startPolling(pollInterval);
    //     unreadCountData.startPolling(pollInterval);
    // };
    // useIdleTimer({ timeout: 300000, onIdle, onActive });

    // const conversations = conversationData.data?.list?.conversations ?? [];

    const HeaderContent = (
        <Fragment key={'pms-header-content'}>
            <MenuTitle>{t('usermenu.private-messages')}</MenuTitle>
            <MarkAsRead
                key={'mark-private-messages-as-read'}
                title={t('usermenu.mark-all-as-read')}
                onClick={() => {
                    const markAsReadUrl = `${runtimeConfig.appDomain}/notifications/dismissPM`;
                    fetch(markAsReadUrl, { method: 'POST' }).then(() => {
                        getPrivateMessagesList();
                    });
                }}
            >
                {t('usermenu.mark-all-as-read')}
            </MarkAsRead>
        </Fragment>
    );

    const BodyContent = (
        <Fragment key={'pms-body-content'}>
            {errors.length > 0 ? (
                errors.map((error, index) => {
                    return (
                        <MenuItemTemplate
                            itemIcon={<MdError size={'25'} />}
                            itemHeading={error}
                            itemDetails={''}
                            key={`private-message-error-${index}`}
                            unread={false}
                        />
                    );
                })
            ) : privateMessagesList.length == 0 ? (
                <MenuItemTemplate
                    itemIcon={<MdOutlineNotificationsActive size={'25'} />}
                    itemHeading={t('usermenu.no-private-messages')}
                    itemDetails={''}
                    key={'no-private-messages'}
                    unread={false}
                />
            ) : (
                privateMessagesList.map((privateMessage: any) => {
                    const pmDetails = privateMessage.info.split(';');
                    const fromUser = pmDetails[0].trim();
                    const timestamp = pmDetails[1].trim().replace(/<\/?[^>]+(>|$)/g, '');

                    return (
                        <MenuItemTemplate
                            itemIcon={
                                <UserAvatar
                                    username={fromUser.replace('From ', '')}
                                    imageUrl={privateMessage.avatarurl || ''}
                                    sx={{
                                        width: '35px',
                                        height: '35px',
                                        marginRight: theme.spacing(3),
                                        border: `1px solid ${theme.outline}`,
                                        animation: 'none'
                                    }}
                                />
                            }
                            itemHeading={privateMessage.title}
                            itemDetails={fromUser}
                            itemTimestamp={timestamp}
                            url={privateMessage.url}
                            onClick={() => {
                                recordClick({
                                    group: 'pms',
                                    section: 'header',
                                    subsection: 'pms menu',
                                    item: privateMessage.group,
                                    action: 'select',
                                    url: runtimeConfig
                                        ? `${runtimeConfig.appDomain}${privateMessage.url}`
                                        : privateMessage.url
                                });
                            }}
                            unread={!privateMessage.dismissed}
                        />
                        // TODO: Conversations
                        // conversations.map((conversation: any) => {
                        //     return (
                        //         <Conversation
                        //             conversation={conversation}
                        //             key={`conversation-${conversation.id}`}
                        //             selected={false}
                        //         />
                        //     );
                        // })
                    );
                })
            )}
        </Fragment>
    );

    const FooterContent = (
        <PMFooterContainer>
            <SeeAll key={'pms-footer-content'} url={'/private.php'} title={t('usermenu.see-all')}>
                {t('usermenu.see-all')}
            </SeeAll>
            <PrimaryOutlinedButton $isOnDark={true} href={"/private.php?do=newpm"} aria-label={t('usermenu.new-message')}>
                {t('usermenu.new-message')}
            </PrimaryOutlinedButton>
            {/* <PrimaryButton
                onClick={() => {
                    reduxDispatch(showModal(true, 'newconversation', {}, true));
                }}
                variant={'contained'}
                color={'primary'}
                startIcon={<MdCreate />}
            >
                {t('usermenu.new-message')}
            </PrimaryButton> */}
        </PMFooterContainer>
    );

    return (
        <MenuTemplate
            MenuToggleContent={
                <MenuBadge
                    badgeContent={unreadCount}
                    sx={{
                        '& .MuiBadge-badge': {
                            color: theme.text.primary,
                            fontWeight: 'bold',
                            backgroundColor: theme.palette.info.main
                        }
                    }}
                >
                    <MdOutlineMail style={{ color: theme.white, width: '30px', height: '30px' }} />
                </MenuBadge>
            }
            MenuHeaderContent={HeaderContent}
            MenuBodyContent={BodyContent}
            MenuFooterContent={FooterContent}
            menuName={'private-messages'}
            open={props.open}
            setOpen={props.setOpen}
        />
    );
}

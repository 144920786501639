export default [
    {
        index: '1',
        titleKey: 'home',
        url: '/'
    },
    {
        index: '2',
        titleKey: 'main-topics',
        url: '/forum.php',
        children: [
            {
                index: '2-1',
                titleKey: 'study-help-discussion',
                children: [
                    {
                        index: '2-1-1',
                        titleKey: 'all-study-help',
                        url: '/forumdisplay.php?f=11'
                    },
                    {
                        index: '2-1-2',
                        titleKey: 'gcses',
                        url: '/forumdisplay.php?f=85'
                    },
                    {
                        index: '2-1-3',
                        titleKey: 'a-level',
                        url: '/forumdisplay.php?f=80'
                    }
                ]
            },
            {
                index: '2-2',
                titleKey: 'university-discussion',
                children: [
                    {
                        index: '2-2-1',
                        titleKey: 'uni-applications',
                        url: '/forumdisplay.php?f=6'
                    },
                    {
                        index: '2-2-2',
                        titleKey: 'university-and-he-colleges',
                        url: '/forumdisplay.php?f=307'
                    },
                    {
                        index: '2-2-3',
                        titleKey: 'university-help-and-courses',
                        url: '/forumdisplay.php?f=3'
                    },
                    {
                        index: '2-2-4',
                        titleKey: 'university-student-life',
                        url: '/forumdisplay.php?f=502'
                    },
                    {
                        index: '2-2-5',
                        titleKey: 'postgraduate',
                        url: '/forumdisplay.php?f=100'
                    },
                    {
                        index: '2-2-6',
                        titleKey: 'medicine',
                        url: '/forumdisplay.php?f=195'
                    }
                ]
            },
            {
                index: '2-3',
                titleKey: 'careers-discussion',
                children: [
                    {
                        index: '2-3-1',
                        titleKey: 'careers-and-jobs',
                        url: '/forumdisplay.php?f=201'
                    },
                    {
                        index: '2-3-2',
                        titleKey: 'teacher-training',
                        url: '/forumdisplay.php?f=97'
                    },
                    {
                        index: '2-3-3',
                        titleKey: 'finance-and-accountancy',
                        url: '/forumdisplay.php?f=262'
                    }
                ]
            },
            {
                index: '2-4',
                titleKey: 'general-discussion',
                children: [
                    {
                        index: '2-4-1',
                        titleKey: 'chat',
                        url: '/forumdisplay.php?f=143'
                    },
                    {
                        index: '2-4-2',
                        titleKey: 'relationships',
                        url: '/forumdisplay.php?f=89'
                    },
                    {
                        index: '2-4-3',
                        titleKey: 'fashion',
                        url: '/forumdisplay.php?f=151'
                    },
                    {
                        index: '2-4-4',
                        titleKey: 'fitness',
                        url: '/forumdisplay.php?f=269'
                    },
                    {
                        index: '2-4-5',
                        titleKey: 'sexual-health',
                        url: '/forumdisplay.php?f=338'
                    },
                    {
                        index: '2-4-6',
                        titleKey: 'give-feedback',
                        url: '/forumdisplay.php?f=1645'
                    }
                ]
            }
        ]
    },
    {
        index: '3',
        titleKey: 'gcse-and-a-level',
        url: '/revision',
        children: [
            {
                index: '3-1',
                titleKey: 'study-level-discussion',
                children: [
                    {
                        index: '3-1-1',
                        titleKey: 'gcse',
                        url: '/forumdisplay.php?f=85'
                    },
                    {
                        index: '3-1-2',
                        titleKey: 'a-level',
                        url: '/forumdisplay.php?f=80'
                    },
                    {
                        index: '3-1-3',
                        titleKey: 'btec',
                        url: '/forumdisplay.php?f=888'
                    },
                    {
                        index: '3-1-4',
                        titleKey: 't-level',
                        url: '/forumdisplay.php?f=1560'
                    },
                    {
                        index: '3-1-5',
                        titleKey: 'scottish-qualifications',
                        url: '/forumdisplay.php?f=41'
                    },
                    {
                        index: '3-1-6',
                        titleKey: 'ib',
                        url: '/forumdisplay.php?f=10'
                    },
                    {
                        index: '3-1-7',
                        titleKey: 'epq',
                        url: '/forumdisplay.php?f=1454'
                    }
                ]
            },
            {
                index: '3-2',
                titleKey: 'study-help-discussion-by-subject',
                children: [
                    {
                        index: '3-2-1',
                        titleKey: 'maths',
                        url: '/forumdisplay.php?f=38'
                    },
                    {
                        index: '3-2-2',
                        titleKey: 'english',
                        url: '/forumdisplay.php?f=82'
                    },
                    {
                        index: '3-2-3',
                        titleKey: 'geography',
                        url: '/forumdisplay.php?f=83'
                    },
                    {
                        index: '3-2-4',
                        titleKey: 'history',
                        url: '/forumdisplay.php?f=141'
                    },
                    {
                        index: '3-2-5',
                        titleKey: 'biology',
                        url: '/forumdisplay.php?f=129'
                    },
                    {
                        index: '3-2-6',
                        titleKey: 'chemistry',
                        url: '/forumdisplay.php?f=130'
                    },
                    {
                        index: '3-2-7',
                        titleKey: 'physics',
                        url: '/forumdisplay.php?f=131'
                    },
                    {
                        index: '3-2-8',
                        titleKey: 'foreign-languages',
                        url: '/forumdisplay.php?f=98'
                    },
                    {
                        index: '3-2-9',
                        titleKey: 'all-study-help',
                        url: '/forumdisplay.php?f=11'
                    }
                ]
            },
            {
                index: '3-3',
                titleKey: 'gcse-and-a-level-articles',
                children: [
                    {
                        index: '3-3-1',
                        titleKey: 'gcse-articles',
                        url: '/gcse/'
                    },
                    {
                        index: '3-3-2',
                        titleKey: 'a-level-articles',
                        url: '/a-level/'
                    },
                    {
                        index: '3-3-3',
                        titleKey: 'exam-and-revision-articles',
                        url: '/revision/'
                    },
                    {
                        index: '3-3-4',
                        titleKey: 'what-to-do-after-gcses',
                        url: '/gcse/what-can-you-do-after-gcses-academic-and-vocational-options-for-16-year-olds'
                    },
                    {
                        index: '3-3-5',
                        titleKey: 'what-to-do-after-a-levels',
                        url: '/a-level/18-year-olds-what-can-you-do-after-a-levels'
                    },
                    {
                        index: '3-3-6',
                        titleKey: 'when-is-a-level-results-day',
                        url: '/results/a-level/when-is-a-level-results-day'
                    },
                    {
                        index: '3-3-7',
                        titleKey: 'when-is-gcse-results-day',
                        url: '/results/gcse/gcse-results-day-when-it-is-and-what-to-expect'
                    }
                ]
            },
            {
                index: '3-4',
                titleKey: 'more-study-help',
                children: [
                    {
                        index: '3-4-1',
                        titleKey: 'studying-revision-and-exam-support',
                        url: '/forumdisplay.php?f=635'
                    },
                    {
                        index: '3-4-2',
                        titleKey: 'grow-your-grades',
                        url: '/forumdisplay.php?f=1659'
                    }
                ]
            }
        ]
    },
    {
        index: '4',
        titleKey: 'university',
        url: '/university/',
        children: [
            {
                index: '4-1',
                titleKey: 'university-discussion',
                children: [
                    {
                        index: '4-1-1',
                        titleKey: 'all-universities',
                        url: '/forumdisplay.php?f=307'
                    },
                    {
                        index: '4-1-2',
                        titleKey: 'applying-through-ucas',
                        url: '/forumdisplay.php?f=6'
                    },
                    {
                        index: '4-1-3',
                        titleKey: 'student-finance',
                        url: '/forumdisplay.php?f=545'
                    },
                    {
                        index: '4-1-4',
                        titleKey: 'personal-statement',
                        url: '/forumdisplay.php?f=1083'
                    },
                    {
                        index: '4-1-5',
                        titleKey: 'postgraduate-study',
                        url: '/forumdisplay.php?f=100'
                    },
                    {
                        index: '4-1-6',
                        titleKey: 'uni-accommodation',
                        url: '/forumdisplay.php?f=289'
                    },
                    {
                        index: '4-1-7',
                        titleKey: 'university-life',
                        url: '/forumdisplay.php?f=502'
                    }
                ]
            },
            {
                index: '4-2',
                titleKey: 'university-course-discussion',
                children: [
                    {
                        index: '4-2-1',
                        titleKey: 'all-uni-courses',
                        url: '/forumdisplay.php?f=3'
                    },
                    {
                        index: '4-2-2',
                        titleKey: 'apprenticeships',
                        url: '/forumdisplay.php?f=516'
                    },
                    {
                        index: '4-2-3',
                        titleKey: 'medicine',
                        url: '/forumdisplay.php?f=195'
                    },
                    {
                        index: '4-2-4',
                        titleKey: 'arts-and-humanities-courses',
                        url: '/forumdisplay.php?f=26'
                    },
                    {
                        index: '4-2-5',
                        titleKey: 'stem-courses',
                        url: '/forumdisplay.php?f=284'
                    },
                    {
                        index: '4-2-6',
                        titleKey: 'social-science-courses',
                        url: '/forumdisplay.php?f=72'
                    }
                ]
            },
            {
                index: '4-3',
                titleKey: 'universities-by-region',
                children: [
                    {
                        index: '4-3-1',
                        titleKey: 'north-of-england',
                        url: '/forumdisplay.php?f=308'
                    },
                    {
                        index: '4-3-2',
                        titleKey: 'midlands',
                        url: '/forumdisplay.php?f=310'
                    },
                    {
                        index: '4-3-3',
                        titleKey: 'south-of-england',
                        url: '/forumdisplay.php?f=309'
                    },
                    {
                        index: '4-3-4',
                        titleKey: 'greater-london',
                        url: '/forumdisplay.php?f=57'
                    },
                    {
                        index: '4-3-5',
                        titleKey: 'scotland',
                        url: '/forumdisplay.php?f=42'
                    },
                    {
                        index: '4-3-6',
                        titleKey: 'wales',
                        url: '/forumdisplay.php?f=150'
                    },
                    {
                        index: '4-3-7',
                        titleKey: 'northern-ireland',
                        url: '/forumdisplay.php?f=204'
                    },
                    {
                        index: '4-3-8',
                        titleKey: 'distance-learning',
                        url: '/forumdisplay.php?f=893'
                    },
                    {
                        index: '4-3-9',
                        titleKey: 'international-study',
                        url: '/forumdisplay.php?f=116'
                    }
                ]
            },
            {
                index: '4-4',
                titleKey: 'university-guides-and-articles',
                children: [
                    {
                        index: '4-4-1',
                        titleKey: 'all-university-articles',
                        url: '/university'
                    },
                    {
                        index: '4-4-2',
                        titleKey: 'applying-to-uni-articles',
                        url: '/university/apply/'
                    },
                    {
                        index: '4-4-3',
                        titleKey: 'personal-statements',
                        url: '/university/personal-statements/'
                    },
                    {
                        index: '4-4-4',
                        titleKey: 'personal-statement-examples',
                        url: '/university/personal-statements/personal-statements-by-subject'
                    },
                    {
                        index: '4-4-5',
                        titleKey: 'freshers',
                        url: '/university/freshers/'
                    },
                    {
                        index: '4-4-6',
                        titleKey: 'university-open-days',
                        url: '/university/open-days/'
                    },
                    {
                        index: '4-4-7',
                        titleKey: 'studying-law-at-university',
                        url: '/university/courses/law/'
                    },
                    {
                        index: '4-4-8',
                        titleKey: 'student-life-at-university',
                        url: 'https://www.theuniguide.co.uk/advice/student-life'
                    }
                ]
            }
        ]
    },
    {
        index: '5',
        titleKey: 'careers-and-jobs',
        url: '/jobs',
        children: [
            {
                index: '5-1',
                titleKey: 'careers-discussion',
                children: [
                    {
                        index: '5-1-1',
                        titleKey: 'careers-and-jobs-forum',
                        url: '/forumdisplay.php?f=201'
                    },
                    {
                        index: '5-1-2',
                        titleKey: 'apprenticeships-forum',
                        url: '/forumdisplay.php?f=516'
                    },
                    {
                        index: '5-1-3',
                        titleKey: 'part-time-and-temp-jobs',
                        url: '/forumdisplay.php?f=148'
                    }
                ]
            },
            {
                index: '5-2',
                titleKey: 'industry-forums',
                children: [
                    {
                        index: '5-2-1',
                        titleKey: 'career-forums-by-sector',
                        url: '/forumdisplay.php?f=8'
                    },
                    {
                        index: '5-2-2',
                        titleKey: 'armed-forces-careers',
                        url: '/forumdisplay.php?f=96'
                    },
                    {
                        index: '5-2-3',
                        titleKey: 'consultancy-careers',
                        url: '/forumdisplay.php?f=94'
                    },
                    {
                        index: '5-2-4',
                        titleKey: 'finance-careers',
                        url: '/forumdisplay.php?f=262'
                    },
                    {
                        index: '5-2-5',
                        titleKey: 'law-careers',
                        url: '/forumdisplay.php?f=263'
                    }
                ]
            },
            {
                index: '5-3',
                titleKey: 'more-industry-forums',
                children: [
                    {
                        index: '5-3-1',
                        titleKey: 'marketing-careers',
                        url: '/forumdisplay.php?f=1613'
                    },
                    {
                        index: '5-3-2',
                        titleKey: 'medicine-careers',
                        url: '/forumdisplay.php?f=513'
                    },
                    {
                        index: '5-3-3',
                        titleKey: 'public-sector-careers',
                        url: '/forumdisplay.php?f=512'
                    },
                    {
                        index: '5-3-4',
                        titleKey: 'engineering-careers',
                        url: '/forumdisplay.php?f=673'
                    },
                    {
                        index: '5-3-5',
                        titleKey: 'teaching-careers',
                        url: '/forumdisplay.php?f=97'
                    }
                ]
            }
        ]
    },
    {
        index: '6',
        titleKey: 'life-and-relationships',
        url: '/life/relationships/',
        children: [
            {
                index: '6-1',
                titleKey: 'life-chat',
                children: [
                    {
                        index: '6-1-1',
                        titleKey: 'general-chat',
                        url: '/forumdisplay.php?f=143'
                    },
                    {
                        index: '6-1-2',
                        titleKey: 'relationships',
                        url: '/forumdisplay.php?f=89'
                    },
                    {
                        index: '6-1-3',
                        titleKey: 'friends-family-and-colleagues',
                        url: '/forumdisplay.php?f=658'
                    },
                    {
                        index: '6-1-4',
                        titleKey: 'advice-on-everyday-issues',
                        url: '/forumdisplay.php?f=611'
                    },
                    {
                        index: '6-1-5',
                        titleKey: 'fashion',
                        url: '/forumdisplay.php?f=151'
                    }
                ]
            },
            {
                index: '6-2',
                titleKey: 'health-chat',
                children: [
                    {
                        index: '6-2-1',
                        titleKey: 'life-and-style',
                        url: '/forumdisplay.php?f=331'
                    },
                    {
                        index: '6-2-2',
                        titleKey: 'general-health',
                        url: '/forumdisplay.php?f=362'
                    },
                    {
                        index: '6-2-3',
                        titleKey: 'sexual-health',
                        url: '/forumdisplay.php?f=338'
                    },
                    {
                        index: '6-2-4',
                        titleKey: 'mental-health',
                        url: '/forumdisplay.php?f=659'
                    },
                    {
                        index: '6-2-5',
                        titleKey: 'fitness',
                        url: '/forumdisplay.php?f=269'
                    }
                ]
            },
            {
                index: '6-3',
                titleKey: 'news-and-entertainment-chat',
                children: [
                    {
                        index: '6-3-1',
                        titleKey: 'debate-and-current-affairs',
                        url: '/forumdisplay.php?f=330'
                    },
                    {
                        index: '6-3-2',
                        titleKey: 'entertainment',
                        url: '/forumdisplay.php?f=13'
                    },
                    {
                        index: '6-3-3',
                        titleKey: 'uk-politics',
                        url: '/forumdisplay.php?f=60'
                    },
                    {
                        index: '6-3-4',
                        titleKey: 'news',
                        url: '/forumdisplay.php?f=1'
                    },
                    {
                        index: '6-3-5',
                        titleKey: 'educational-debate',
                        url: '/forumdisplay.php?f=22'
                    },
                    {
                        index: '6-3-6',
                        titleKey: 'football',
                        url: '/forumdisplay.php?f=156'
                    },
                    {
                        index: '6-3-7',
                        titleKey: 'sport',
                        url: '/forumdisplay.php?f=54'
                    }
                ]
            }
        ]
    },
    {
        index: '7',
        titleKey: 'student-finance',
        url: '/student-finance',
        children: [
            {
                index: '7-1',
                titleKey: 'undergraduate',
                children: [
                    {
                        index: '7-1-1',
                        titleKey: 'full-time',
                        url: '/student-finance/full-time'
                    },
                    {
                        index: '7-1-2',
                        titleKey: 'part-time',
                        url: '/student-finance/part-time'
                    }
                ]
            },
            {
                index: '7-2',
                titleKey: 'postgraduate',
                children: [
                    {
                        index: '7-2-1',
                        titleKey: 'postgraduate-masters-loan',
                        url: '/student-finance/postgraduate/postgraduate-masters-loan'
                    },
                    {
                        index: '7-2-2',
                        titleKey: 'postgraduate-doctoral-loan',
                        url: '/student-finance/postgraduate-doctoral-loan'
                    },
                    {
                        index: '7-2-3',
                        titleKey: 'disabled-students-allowances',
                        url: '/student-finance/disabled-students-allowance'
                    },
                    {
                        index: '7-2-4',
                        titleKey: 'taking-a-break-or-withdrawing-from-your-course',
                        url: '/student-finance/postgraduate-students-taking-a-break-or-withdrawing-from-your-studies'
                    }
                ]
            },
            {
                index: '7-3',
                titleKey: 'further-information',
                children: [
                    {
                        index: '7-3-1',
                        titleKey: 'parents-and-partners',
                        url: '/student-finance/parents-and-partners'
                    },
                    {
                        index: '7-3-2',
                        titleKey: 'repayment',
                        url: '/student-finance/loan-repayment'
                    },
                    {
                        index: '7-3-3',
                        titleKey: 'advanced-learner-loan',
                        url: '/student-finance/advanced-learner-loan'
                    }
                ]
            }
        ]
    }
];

import styled from '@emotion/styled';

// MUI Components
import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';

// Components
import Link from '../../../../../components/Link';
import TSRButton from '../../../../../components/Buttons';

export const UsernameAccordion = styled(MuiAccordion)`
    background-color: transparent;
    box-shadow: none;
    height: auto;
    min-height: 30px;
    text-decoration: none;
    color: ${(props) => props.theme.text.secondary};
    text-transform: capitalize;
    line-height: 10px;
    margin-top: ${(props) => props.theme.spacing(1)};
    padding: ${(props) => props.theme.spacing(0, 2, 0, 0)};

    &.Mui-expanded {
        margin: ${(props) => props.theme.spacing(0)};
    }

    &::before {
        background-color: transparent;
    }
`;

export const UserMenuAccordionDetails = styled(AccordionDetails)`
    padding: ${(props) => props.theme.spacing(0)};
    margin-right: ${(props) => props.theme.spacing(4)};
    display: flex;
    flex-direction: column;
`;

export const UsernameAccordionSummary = styled(AccordionSummary)`
    background-color: transparent;
    height: 30px;
    min-height: 30px;

    &.Mui-expanded {
        min-height: 30px;
    }

    &::before {
        background-color: transparent;
    }
`;

export const UserMenuDetails = styled(Box)`
    display: inline-flex;
    flex-direction: row;
    margin-bottom: ${(props) => props.theme.spacing(1)};
    padding: ${(props) => props.theme.spacing(1)};
`;

export const UserMenuItem = styled.div`
    text-decoration: none;
    font-weight: 400;
    color: ${(props) => props.theme.palette.primary.contrastText};
    text-transform: capitalize;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;

export const UserMenuLink = styled(Link)<{ level: number }>`
    background-color: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 400;
    padding: ${(props) => props.theme.spacing(2)};
    white-space: nowrap;
    margin-left: ${(props) =>
        props.level == 0 ? props.theme.spacing(0) : props.theme.spacing(9)};
    text-transform: capitalize;
    color: ${(props) => props.theme.palette.primary.contrastText};
    line-height: 10px;
    margin-bottom: ${(props) => props.theme.spacing(2)};

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;

export const UsernameContainer = styled.div`
    width: fit-content;
    display: flex;
    flex-direction: row;
    margin-right: ${(props) => props.theme.spacing(2)};
`;

export const UsernameMenuText = styled(Link)`
    ${(props) => props.theme.typography.button};
    color: ${(props) => props.theme.palette.primary.contrastText};
    padding: ${(props) => props.theme.spacing(2)};
    margin: ${(props) => props.theme.spacing(0)};
    box-shadow: none;
    min-width: 34px;
    height: 34px;
    cursor: default;
    text-transform: none;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;

export const UserMenuPersonalisation = styled(TSRButton)`
    width: fit-content;
    justify-content: flex-start;
    margin-left: 3px;
`;

export const AvatarContainer = styled.div`
    display: flex;
    justify-content: center;
    background-color: ${(props) => props.theme.palette.primary.main};
    border-radius: 100px;
    width: 24px;
    height: 24px;
    margin: ${(props) => props.theme.spacing(0)};

    @media (min-width: 501px) {
        width: 27px;
        height: 27px;
        margin: ${(props) => props.theme.spacing(0, 2, 0, 0)};
    }
`;

export const StyledIdent = styled.img`
    align-self: center;
    height: 23px;
    width: 23px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(12%) saturate(1997%) hue-rotate(175deg) brightness(120%) contrast(101%);
`;

import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import { MdSearch, MdHighlightOff as ClearSearchIcon } from 'react-icons/md';
import useTranslation from '../../hooks/useTranslation';
import { useAppTheme, TextField } from '@thestudentroom/lib.themes';

const SearchIconButton = styled(IconButton)`
    margin-left: ${(props) => props.theme.spacing(1)};
    padding: ${(props) => props.theme.spacing(1)};
    background-color: ${(props) => props.theme.palette.success.main};
    border-radius: 2px;
    height: 38px;
    width: 38px;

    &:hover {
        background-color: ${(props) => props.theme.palette.success.dark};
    }
`;

interface SearchBarProps {
    id?: string;
    autoFocus: boolean;
    value: string;
    handleChange: (value: string) => void;
    submitSearch: (value: string) => void;
    showSubmitButton?: boolean;
    startIcon?: React.ReactElement;
    inputStyle?: React.CSSProperties;
}

export default ({
    id,
    autoFocus,
    value,
    handleChange,
    submitSearch,
    showSubmitButton,
    startIcon,
    inputStyle
}: SearchBarProps) => {
    const { t } = useTranslation();
    const theme = useAppTheme();

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            submitSearch(value);
            event.target.blur();
        }
    };

    return (
        <TextField
            id={id ?? 'search-bar'}
            name="search"
            onChange={(event) => handleChange(event.target.value)}
            onKeyDown={handleKeyPress}
            value={value}
            placeholder={t('header.search')}
            inputProps={{
                'aria-label': t('header.search'),
                pattern: 'search',
                inputMode: 'search'
            }}
            autoComplete={'off'}
            autoFocus={autoFocus}
            startIcon={startIcon}
            endIcon={
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {
                        value && (
                            <IconButton onClick={() => handleChange('')} aria-label="clear search">
                                <ClearSearchIcon color={theme.palette.primary.main} size={24} />
                            </IconButton>
                        )
                    }
                    {showSubmitButton && (
                        <SearchIconButton
                            onClick={() => submitSearch(value)}
                            type="submit"
                            aria-label={t('header.search')}
                        >
                            <MdSearch color={theme.text.primary} size={24} />
                        </SearchIconButton>
                    )}
                </div>
            }
            sx={{ width: '100%' }}
            style={inputStyle}
        />
    );
};

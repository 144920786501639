import styled from '@emotion/styled';
import Button, { ButtonProps } from '@mui/material/Button';

export default function TSRButton(props: ButtonProps) {
    return (
        <Button {...props} disableRipple disableFocusRipple>
            {props.children}
        </Button>
    );
}

export const PrimaryButton = styled(TSRButton)`
    background-color: ${(props) => props.theme.palette.success.main};
    color: ${(props) => props.theme.text.primary};
    box-shadow: none;
    padding: ${(props) => props.theme.spacing(3)};
    border-radius: 8px;
    text-transform: none;
    ${(props) => props.theme.typography.subtitle2};

    &:hover {
        background-color: ${(props) => props.theme.palette.success.dark};
        box-shadow: none;
    }

    &:focus-visible {
        outline: 2px solid ${(props) => props.theme.button.focusState};
    }

    &:active {
        background-color: ${(props) => props.theme.palette.success.dark};
    }
`;

import { Fragment, useState, useEffect, useRef } from 'react';
import useTranslation from '../../../../../hooks/useTranslation';
import { runtimeConfig } from '../../../../../runtimeConfig';
import { recordClick } from '@thestudentroom/datalayer';
import { useQuery as useGraphQLQuery } from '@apollo/client';
import { useIdleTimer } from 'react-idle-timer';

// Styles and Templates
import MenuTemplate, { MenuItemTemplate } from '../Template';
import { MarkAsRead, SeeAll, MenuTitle, MenuBadge } from '../Template/styles';
import { MdNotifications } from 'react-icons/md';
import { useAppTheme } from '@thestudentroom/lib.themes';

// GraphQL Queries
import {
    GET_NOTIFICATION_COUNT,
    GET_NOTIFICATION_LIST
} from '../../../../../api/Notification/graph';

// Helpers
import { timestampCalculation } from '../../../../../helpers/timestampCalculation';
import { getNotificationIcon } from '../../../../../helpers/getNotificationIcon';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../../../store/modals/actions';

interface NotificationsToolbarProps {
    open: string | false;
    setOpen: Function;
}

export default function NotificationsToolbar(props: NotificationsToolbarProps) {
    const { t } = useTranslation();
    const theme = useAppTheme();
    const reduxDispatch = useDispatch();
    const notificationsRef = useRef<HTMLDivElement>(null);
    const [error, setError] = useState('');
    const pollInterval = 30000; // refresh notification count every 30 seconds
    
    const PER_PAGE = 20;

    const {
        data: notificationUnreadData,
        refetch: refetchNotificationCount,
        startPolling: startPollingNotificationCount,
        stopPolling: stopPollingNotificationCount
    } = useGraphQLQuery(GET_NOTIFICATION_COUNT, {
        errorPolicy: 'all',
        ssr: true
    });

    const { data: notificationListData, refetch: refetchNotificationList } = useGraphQLQuery(
        GET_NOTIFICATION_LIST,
        {
            errorPolicy: 'all',
            variables: { page: 1, per_page: PER_PAGE },
            ssr: true
        }
    );

    useEffect(() => {
        // start polling for new notifications every 30 seconds
        startPollingNotificationCount(pollInterval);

        return () => {
            // stop polling when the component unmounts
            stopPollingNotificationCount();
        };
    }, []);

    // if the menu is opened refresh the current notifications
    useEffect(() => {
        if (props.open == 'notifications') {
            getNotificationCount();
            getNotificationsList();
            // reset scroll position
            if (notificationsRef.current) {
                notificationsRef.current.scrollTop = 0;
            }
        }
    }, [props.open]);

    const [unreadCount, setUnreadCount] = useState(notificationUnreadData?.notificationCount ?? 0);
    const [notificationsList, setNotificationsList] = useState<string[]>(
        notificationListData?.notification_list?.notifications ?? []
    );

    // stop polling if the user is idle
    // start polling when they become active again
    const onIdle = () => {
        stopPollingNotificationCount();
    };
    const onActive = () => {
        startPollingNotificationCount(pollInterval);
    };
    useIdleTimer({ timeout: 60000, onIdle: onIdle, onActive: onActive });

    const getNotificationCount = async () => {
        const { data: notificationUnreadData } = await refetchNotificationCount();
        setUnreadCount(notificationUnreadData?.notificationCount ?? 0);
    };

    const getNotificationsList = async () => {
        const { data: notificationListData } = await refetchNotificationList({ page: 1, per_page: PER_PAGE });

        if (notificationListData.notification_list) {
            setNotificationsList(notificationListData?.notification_list?.notifications ?? []);
            setError('');
        } else {
            setError(t('errors.notifications-menu'));
        }
    };

    const toggleDataCapture = () => {
        props.setOpen(false);
        // Open data capture
        reduxDispatch(showModal(true, 'dpc_continue', {}, true));
    };

    const handleScroll = async () => {
        if (!notificationsRef.current) return;

        const { scrollTop, scrollHeight, clientHeight } = notificationsRef.current;

        // if the user has scrolled to the bottom and
        // there are more notifications, load more
        if (
            scrollTop + clientHeight >= scrollHeight - 5 &&
            notificationsList.length < notificationListData?.notification_list?.total_results
        ) {
            const nextPage = Math.ceil(notificationsList.length / PER_PAGE) + 1;
            
            const { data: notificationListData } = await refetchNotificationList(
                { page: nextPage, per_page: PER_PAGE }
            );
            
            if (notificationListData?.notification_list?.notifications) {
                setNotificationsList([...notificationsList, ...notificationListData.notification_list.notifications]);
            }
        }
    }

    useEffect(() => {
        if (notificationsRef.current) {
            notificationsRef.current.addEventListener('scroll', handleScroll);
        }        
        
        return () => {
            if (notificationsRef.current) {
                notificationsRef.current.removeEventListener('scroll', handleScroll);
            }
        }
    }, [notificationsRef.current, notificationsList.length, handleScroll]);

    const HeaderContent = (
        <Fragment key={'notifications-header-content'}>
            <MenuTitle>{t('usermenu.notifications')}</MenuTitle>
            <MarkAsRead
                key={'mark-notifications-as-read'}
                title={t('usermenu.mark-all-as-read')}
                onClick={() => {
                    const markAsReadUrl = `${runtimeConfig.appDomain}/notifications/dismiss`;
                    fetch(markAsReadUrl, { method: 'PATCH' }).then(() => {
                        getNotificationsList();
                        getNotificationCount();
                    });
                }}
            >
                {t('usermenu.mark-all-as-read')}
            </MarkAsRead>
        </Fragment>
    );

    const BodyContent = (
        <Fragment key={'notifications-body-content'}>
            {getNotificationDisplay(
                notificationsList,
                error,
                getNotificationsList,
                getNotificationCount,
                toggleDataCapture,
                t
            )}
        </Fragment>
    );

    const FooterContent = (
        <SeeAll
            key={'notifications-footer-content'}
            url={'/notifications.php'}
            title={t('usermenu.see-all')}
        >
            {t('usermenu.see-all')}
        </SeeAll>
    );

    return (
        <Fragment key={'notifications-menu-container'}>
            <MenuTemplate
                MenuToggleContent={
                    <MenuBadge
                        badgeContent={unreadCount}
                        sx={{
                            '& .MuiBadge-badge': {
                                color: theme.text.primary,
                                fontWeight: 'bold',
                                backgroundColor: theme.palette.info.main
                            }
                        }}
                        data-testid={'unread-notifications'}
                    >
                        <MdNotifications
                            style={{ color: theme.white, width: '30px', height: '30px', marginRight: theme.spacing(-1) }}
                        />
                    </MenuBadge>
                }
                MenuHeaderContent={HeaderContent}
                MenuBodyContent={BodyContent}
                MenuFooterContent={FooterContent}
                menuName={'notifications'}
                open={props.open}
                setOpen={props.setOpen}
                menuSectionRef={notificationsRef}
            />
        </Fragment>
    );
}

function getNotificationDisplay(
    notificationsList: string[],
    error: string,
    getNotificationsList: Function,
    getNotificationCount: Function,
    toggleDataCapture: Function,
    t: Function
) {
    if (notificationsList == null || error.length !== 0) {
        return (
            <MenuItemTemplate
                itemIcon={getNotificationIcon('error')}
                itemHeading={error ?? t('usermenu.no-notifications')}
                itemDetails={''}
                key={`notification-error`}
                unread={false}
            />
        );
    } else if (notificationsList.length == 0) {
        return (
            <MenuItemTemplate
                itemIcon={getNotificationIcon('error')}
                itemHeading={t('usermenu.no-notifications')}
                itemDetails={''}
                key={'no-notifications'}
                unread={false}
            />
        );
    } else {
        return notificationsList.map((notification: any) => {
            return (
                <MenuItemTemplate
                    key={`notification-${notification.id}`}
                    itemIcon={getNotificationIcon(notification.group)}
                    itemHeading={notification.title}
                    itemDetails={notification.from_user}
                    itemTimestamp={timestampCalculation(notification.created_at)}
                    url={notification.group == 'dpc_continue' ? '' : notification.url}
                    onClick={() => {
                        recordClick({
                            group: 'notifications',
                            section: 'header',
                            subsection: 'notifications menu',
                            item: notification.group,
                            action: 'select',
                            url: runtimeConfig
                                ? `${runtimeConfig.appDomain}${notification.url}`
                                : notification.url
                        });

                        if (!notification.dismissed) {
                            const dismissURL = `${runtimeConfig.appDomain}/notifications/singledismiss?id=${notification.id}`;
                            fetch(dismissURL, { method: 'PATCH' }).then(() => {
                                getNotificationsList();
                                getNotificationCount();
                            });
                        }

                        if (notification.group == 'dpc_continue') {
                            toggleDataCapture();
                        }
                    }}
                    unread={!notification.dismissed}
                />
            );
        });
    }
}

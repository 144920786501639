import { AnyAction } from 'redux';
import { SET_COOKIE } from './actions';

export const initialState: Array<string> = [];

export default function cookieReducer(state = initialState, action: AnyAction) {
    switch (action.type) {
        case SET_COOKIE:
            if (state.includes(action.payload.cookie)) {
                return state;
            }
            return [...state, action.payload.cookie];
        default:
            return state;
    }
}

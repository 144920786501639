import { ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { parse, fix } from 'postcode';
import { RootState } from '../../client';
import getFisherBucket from '../../helpers/getFisherBucket';
import { recordAdvert } from '@thestudentroom/datalayer';
import { UserReducerState } from '../../store/user/reducer';
import { targetingParamType } from '../../store/adverts/reducer';

interface TSRAdsProviderProps {
    children: ReactNode;
}

let adsInit: boolean = false;

export function getGlobalTargetingParams(
    user: UserReducerState,
    pageTargeting: targetingParamType,
    fisherBucket: number
): {
    [key: string]: string | string[];
} {
    let postCode = user.post_code ? parse(fix(user.post_code)) : null;

    const uniStartYear = user.future_qualifications ? user.future_qualifications.find((item) => item.qualification_id === 28)?.start_year : '';

    return {
        u_a: user.age ?? 'ukg',
        u_a_t: `tsr-${user.userGroupId}`,
        // TODO - Below needs adjusting for members
        u_e_s: 'ukg',
        // END TODO
        fisher: fisherBucket.toString(),

        // api.profile output
        u_post_code: postCode && postCode.valid ? postCode.outcode : '',
        u_country: user.country ?? '',
        u_career_phase: user.career_phase ?? '',
        u_qualifications: user.current_qualifications
            ? user.current_qualifications.map((qual: any) => qual?.qualification_id?.toString())
            : [],
        u_year_group: user.year_group ? user.year_group.toString() : '',
        u_cur_subjects: user.current_subjects
            ? user.current_subjects.map((subject: any) => subject.toString())
            : [],
        u_fut_subjects: user.future_subjects
            ? user.future_subjects.map((subject: any) => subject.toString())
            : [],
        u_cur_learning_prov: user.current_learning_providers
            ? user.current_learning_providers.map((lp: any) => lp.toString())
            : [],
        u_fut_learning_prov: user.future_learning_providers
            ? user.future_learning_providers.map((lp: any) => lp.toString())
            : [],
        u_uni_start_year: uniStartYear ?? '',

        // Segmentation
        main_user_topics: user?.segments?.main,
        sup_user_topics: user?.segments?.super,
        high_user_topics: user?.segments?.high,
        med_user_topics: user?.segments?.medium,
        low_user_topics: user?.segments?.low,
        all_user_topics: user?.segments?.all,
        user_topics: user?.segments?.adverts,

        ...pageTargeting
    };
}

const TSRAdsProvider = function (props: TSRAdsProviderProps) {
    const user = useSelector((state: RootState) => state.user);
    const pageTargeting = useSelector((state: RootState) => state.adverts.pageTargeting);
    const targetingKeyValues = getGlobalTargetingParams(user, pageTargeting, getFisherBucket());

    const lazyLoadSettings: any = useFeatureValue('ad-lazy-load-settings', {
        fetchMarginPercent: 47,
        renderMarginPercent: 47,
        mobileScaling: 1.0
    });

    useEffect(() => {
        window.googletag = window.googletag || { cmd: [] };
        googletag.cmd.push(() => {
            for (const [key, value] of Object.entries(targetingKeyValues)) {
                googletag.pubads().setTargeting(key, value);
            }
        });
    }, [targetingKeyValues]);

    useEffect(() => {
        if (!adsInit && typeof window !== 'undefined') {
            window.googletag = window.googletag || { cmd: [] };

            googletag.cmd.push(() => {
                googletag.pubads().enableLazyLoad(lazyLoadSettings);

                googletag.pubads().disableInitialLoad();

                // Enable the PubAdsService.
                googletag.enableServices();

                googletag.pubads().addEventListener('slotOnload', function (event) {
                    var adInfo = event.slot.getResponseInformation();
                    if (adInfo != null) {
                        recordAdvert(
                            'advert_viewable',
                            event.slot.getAdUnitPath(),
                            adInfo.advertiserId ?? 0,
                            adInfo.campaignId ?? 0,
                            adInfo.creativeId ?? 0,
                            adInfo.lineItemId ?? 0
                        );
                    }
                });

                googletag.pubads().addEventListener('impressionViewable', function (event) {
                    var adInfo = event.slot.getResponseInformation();
                    if (adInfo != null) {
                        recordAdvert(
                            'advert_impression',
                            event.slot.getAdUnitPath(),
                            adInfo.advertiserId ?? 0,
                            adInfo.campaignId ?? 0,
                            adInfo.creativeId ?? 0,
                            adInfo.lineItemId ?? 0
                        );
                    }
                });
            });

            adsInit = true;
        }
    }, []);

    return <>{props.children}</>;
};

export default TSRAdsProvider;

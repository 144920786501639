import { memo } from 'react';
import styled from '@emotion/styled';
import Link from '../../../components/Link';
import { recordVirtualPageView } from '@thestudentroom/datalayer';
import { runtimeConfig } from '../../../runtimeConfig';

const HeaderLogoContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: start;
    margin: ${(props) => props.theme.spacing(0, 5)};

    @media (min-width: 1350px) {
        margin: ${(props) => props.theme.spacing(0)};
    }

    @media (min-width: 981px) and (max-width: 1349px) {
        margin: ${(props) => props.theme.spacing(0, 4)};
    }
`;

const StyledLink = styled(Link)`
    align-self: flex-start;
`;

const StyledLogo = styled.img`
    margin-top: ${(props) => props.theme.spacing(0)};
    width: 89px;
    height: 31px;

    @media (min-width: 981px) {
        width: 150px;
        height: 50px;
    }

    @media (min-width: 1350px) {
        margin-top: ${(props) => props.theme.spacing(-3)};
    }

    @media (min-width: 1111px) and (max-width: 1349px) {
        margin-top: ${(props) => props.theme.spacing(0)};
    }

    @media (min-width: 981px) and (max-width: 1110px) {
        margin-top: ${(props) => props.theme.spacing(2)};
    }
`;

const HeaderLogo = () => {
    const sendPageViewTracking = () => {
        recordVirtualPageView(`/virtual/header-bar/home`);
    };

    return (
        <StyledLink url={'/'} onClick={sendPageViewTracking} data-testid="logo-link">
            <HeaderLogoContainer>
                <StyledLogo
                    src={runtimeConfig.publicDomain + '/theme/tsr/images/logos/logo-colour.svg'}
                    alt="The Student Room Group"
                />
            </HeaderLogoContainer>
        </StyledLink>
    );
};

export default memo(HeaderLogo);

import { UserGroup } from 'types/user';
import { MemberType } from '@thestudentroom/datalayer';

export default function getMemberType(userGroups: UserGroup[]): MemberType {
    // get a list of just the IDs, as the other info isn't needed
    const userGroupIds = userGroups.map((item) => item.id);

    // return the usergroup based on the IDs found
    if ([143, 6].some((i) => userGroupIds.includes(i))) {
        return 'staff';
    } else if (
        [5, 157, 135, 216, 242, 244, 246, 248, 250, 252, 254].some((i) => userGroupIds.includes(i))
    ) {
        return 'volunteer';
    } else if ([137, 153, 165, 167].some((i) => userGroupIds.includes(i))) {
        return 'official_rep';
    } else if ([3, 4].some((i) => userGroupIds.includes(i))) {
        return 'member_unconfirmed';
    } else if ([12, 162].some((i) => userGroupIds.includes(i))) {
        return 'member_established';
    } else if (userGroupIds.includes(2)) {
        return 'member_probationary';
    } else if (userGroupIds.includes(8)) {
        return 'member_banned';
    } else if (userGroupIds.includes(160)) {
        return 'member_fake_email';
    } else if (userGroupIds.includes(212)) {
        return 'member_deleted';
    } else {
        return 'member_other';
    }
}

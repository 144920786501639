import styled from '@emotion/styled';

export const BottomSheet = styled.div`
    background-color: ${(props) => props.theme.palette.tertiary.main};
    z-index: 620;
    width: 100% !important;
    height: ${(props) => (props.className == 'hidden' ? 0 : 'auto')} !important;
    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    text-align: center;
`;

import { useState } from 'react';
import styled from '@emotion/styled';

import ErrorBoundary from '../../../../components/ErrorBoundary';
import MenuError from './MenuError';
import MemberToolbar from './MemberToolbar/MemberToolbar';
import NotificationsToolbar from './NotificationsToolbar';
import PrivateMessagesToolbar from './PrivateMessagesToolbar';

const UserMenuContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export default function UserMenus() {
    const [open, setOpen] = useState<string | false>(false);

    return (
        <UserMenuContainer>
            <ErrorBoundary
                key={'notifications-menu-error'}
                errorComponent={<MenuError menuName={'notifications'} />}
            >
                <NotificationsToolbar open={open} setOpen={setOpen} key={'notifications-toolbar'} />
            </ErrorBoundary>

            <ErrorBoundary
                key={'pms-menu-error'}
                errorComponent={<MenuError menuName={'private-messages'} />}
            >
                <PrivateMessagesToolbar open={open} setOpen={setOpen} key={'pms-toolbar'} />
            </ErrorBoundary>

            <ErrorBoundary
                key={'user-menu-error'}
                errorComponent={<MenuError menuName={'usermenu'} />}
            >
                <MemberToolbar open={open} setOpen={setOpen} key={'member-toolbar'} />
            </ErrorBoundary>
        </UserMenuContainer>
    );
}

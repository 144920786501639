import { useState, useEffect } from 'react';
import Link from '@mui/material/Link';
import { PrimaryOutlinedButton } from '@thestudentroom/lib.themes';
import useTranslation from '../../../hooks/useTranslation';
import { recordVirtualPageView } from '@thestudentroom/datalayer';
import styled from '@emotion/styled';

const StyledLink = styled(Link)`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export default function LoginButton() {
    const { t } = useTranslation();
    const currentUrl = typeof window !== 'undefined' ? window.location.href : '';
    const [parentUrl, setParentUrl] = useState('');

    const sendPageViewTracking = () => {
        recordVirtualPageView('/virtual/header-bar/login?action=click');
    };

    // update parent url whenever current url changes
    useEffect(() => {
        setParentUrl(encodeURIComponent(currentUrl));
    }, [currentUrl]);

    return (
        <StyledLink
            href={`/signin.php?parent_url=${parentUrl}`}
            underline="none"
            onClick={sendPageViewTracking}
        >
            <PrimaryOutlinedButton $isOnDark={true} aria-label={t('buttons.login')}>
                {t('buttons.login')}
            </PrimaryOutlinedButton>
        </StyledLink>
    );
}
